import React, {useEffect, useMemo, useState} from 'react';
import {IBulkUploadRow} from "../../model/IBulkUpload";
import {format} from "date-fns";
import CategoryPicker from "../ui/transaction/CategoryPicker";
import {categoryApi} from "../../api/CategoriesService";
import {TransactionType} from "../../model/TransactionType";
import {classNames} from "../../utils/common";
import CommentField from "../ui/transaction/CommentField";
import TagsPicker from "../ui/transaction/TagsPicker";
import {Tag} from "../../model/ICategory";

const BulkUploadRowItem: React.FunctionComponent<{row: IBulkUploadRow, handleCategoryChange(event: any): void , checked: boolean, accountCurrencySymbol: string | undefined}> = ({row, handleCategoryChange, checked, accountCurrencySymbol}) => {
    const [category, setCategory] = useState(null as any);
    const [tags, setTags] = useState([] as Tag[]);
    const [comment, setComment] = useState(row.comment ? row.comment : "");
    const [status, setStatus] = useState(row.status);
    const [modifiedCategory, setModifiedCategory] = useState(false);
    const [modifiedComment, setModifiedComment] = useState(false);
    const [modifiedTags, setModifiedTags] = useState(false);
    const {data: expenseCategories, isLoading: expCatLoading} = categoryApi.useFetchAllCategoriesQuery(TransactionType.Expense);
    const {data: incomeCategories, isLoading: incCatLoading} = categoryApi.useFetchAllCategoriesQuery(TransactionType.Income);
    const {data: tagsList} = categoryApi.useFetchAllTagsQuery();
    const [patchBulkUploadRow, {isLoading: isPatching}] = categoryApi.usePatchBulkUploadRowMutation();

    useEffect(() => {
        if (!row.category) {
            return;
        }
        if (row.amount < 0) {
            if (expenseCategories && expenseCategories.length !== 0) {
                handleSetCategory(expenseCategories.find(cat => cat.id === row.category));
            }
        } else {
            if (incomeCategories && incomeCategories.length !== 0) {
                handleSetCategory(incomeCategories.find(cat => cat.id === row.category));
            }
        }
    }, [expenseCategories, incomeCategories, row.category]);

    useEffect(() => {
        handleSetComment(row.comment ? row.comment : "");
    }, [row.comment]);

    useEffect(() => {
        if (tagsList) {
            setTags(tagsList.filter(tag => row.tags.some(trTag => trTag.id === tag.id)));
        }
    }, [tagsList, row.tags]);

    const handleSetComment = (newComment: string) => {
        setModifiedComment(!(row.comment === null && newComment.length === 0 || newComment === row.comment));
        setComment(newComment);
    }

    const handleSetTags = (newTags: Tag[]) => {
        setModifiedTags(!(
            row.tags === null && newTags === null
            || row.tags.length === 0 && newTags.length === 0
            || newTags.every(tag => row.tags.map(tag => tag.id).includes(tag.id))
            && row.tags.every(tag => newTags.map(tag => tag.id).includes(tag.id))));
        setTags(newTags);
    }

    const handleSetCategory = (newCategory: any) => {
        if (!newCategory) {
            return;
        }
        setModifiedCategory(newCategory.id !== row.category);
        setCategory(newCategory);
    }

    const handleToggleStatus = (e: any) => {
        if (status === 'CREATED') {
            return;
        }
        const newStatus = status === 'UPLOADED' ? 'EXCLUDED' : 'UPLOADED';
        patchBulkUploadRow({id: row.id, status: newStatus});
        setStatus(newStatus);
    }

    const modified = useMemo(() => {
        return modifiedCategory || modifiedComment || modifiedTags;
    }, [modifiedCategory, modifiedComment, modifiedTags]);

    const handleSave = () => {
        if (!modified) {
            alert("Nothing to Save");
            return;
        }
        patchBulkUploadRow({
            id: row.id,
            category: category ? category.id : undefined,
            comment: comment ? comment : undefined,
            tag_ids: tags.map(tag => tag.id)});
        setModifiedCategory(false);
        setModifiedComment(false);
        setModifiedTags(false);
    }

    return (
        <tr className={status === 'EXCLUDED' ? "bg-red-100" : category && !modified ? "bg-green-100" : ""}>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div className="flex h-6 items-center">
                    <input
                        id={"checkbox_" + row.id}
                        name="row_checkbox"
                        type="checkbox"
                        value={row.id}
                        checked={checked}
                        onChange={handleCategoryChange}
                        className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {format(new Date(row.date), 'dd MMM yyyy HH:MM:SS')}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span
                    className={classNames("px-2 inline-flex text-xs leading-5 font-semibold rounded-full", row.amount < 0 ? "bg-red-100 text-red-800" : "bg-green-100 text-green-800")}>
                    {row.currency_symbol + ' ' + row.amount}
                </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {row.commission_moneyback}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span
                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                    {accountCurrencySymbol + ' ' + row.total}
                </span>
            </td>
            <td className="px-6 py-4 text-sm text-gray-500">
                {row.description}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {row.category_description}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {expenseCategories && incomeCategories && <CategoryPicker label={null} category={category} setCategory={handleSetCategory}
                                                                          categories={row.amount < 0 ? expenseCategories : incomeCategories}/>}
                {modifiedCategory && <div className="static w-full">
                    <div className="relative">
                        <div className="absolute top-px rounded-md bg-green-300 left-0 right-0" style={{height: "3px"}}></div>
                    </div>
                </div>}
            </td>
            <td className="relative px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <CommentField comment={comment} setComment={handleSetComment} withLabel={false} className="relative"/>
                {modifiedComment && <div className="static w-full">
                    <div className="relative">
                        <div className="absolute top-px rounded-md bg-green-300 left-0 right-0" style={{height: "3px"}}></div>
                    </div>
                </div>}
            </td>
            <td className="relative px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {tagsList && tagsList.length !== 0 && <TagsPicker label={null} tags={tags} setTags={handleSetTags} tagsList={tagsList}/>}
                {modifiedTags && <div className="static w-full">
                    <div className="relative">
                        <div className="absolute top-px rounded-md bg-green-300 left-0 right-0" style={{height: "3px"}}></div>
                    </div>
                </div>}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button className="cursor-pointer text-green-600 hover:text-green-900"
                        onClick={handleSave}
                >
                    Save
                </button>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div className={classNames(
                    status === 'UPLOADED' ? "bg-blue-100 text-blue-800" : status === 'EXCLUDED' ? "bg-red-100 text-red-800" : "bg-green-100 text-green-800",
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full")}
                >
                    {status === 'UPLOADED' ? 'Uploaded' : status === 'EXCLUDED' ? 'Excluded' : 'Created'}
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button
                    className={classNames("cursor-pointer", status === 'UPLOADED' ? "text-red-600 hover:text-red-900" : status === 'EXCLUDED' ? "text-blue-600 hover:text-blue-900" : "")}
                    disabled={isPatching || status === 'CREATED'}
                    onClick={handleToggleStatus}
                >
                    {status === 'UPLOADED' ? 'Exclude' : status === 'EXCLUDED' ? 'Restore' : '-'}
                </button>
            </td>
        </tr>
    );
};

export default BulkUploadRowItem;