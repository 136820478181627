import React from 'react';
import {classNames} from "../../utils/common";

const Filter = ({filter, setFilter, withLabel = true, wideField = true, ...props}) => {
    return (
        <div {...props}>
            {withLabel && <label htmlFor="filter" className="ml-3 block text-sm font-medium text-gray-700">Filter</label>}
            <div className="mt-1 relative rounded-md shadow-sm h-10">
                <input value={filter} onChange={(event) => setFilter(event.target.value)} type="text" id="filter"
                       className={classNames("focus:ring-indigo-500 focus:border-indigo-500 block h-10 px-3 sm:text-sm border-gray-300 rounded-md", wideField ? "w-96" : "")}/>
                {filter !== '' && <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-red-900 cursor-pointer"
                    onClick={() => setFilter('')}
                >Reset</div>}
            </div>
        </div>
    );
};

export default Filter;