import React from 'react';
import {categoryApi} from "../../api/CategoriesService";

const AccountTotalBalanceTableItem = () => {
    const {data: totalBalance, isLoading: balanceLoading} = categoryApi.useFetchAccountsTotalBalanceQuery();

    return (
        <tr>
            <td className="px-4 py-2 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                    </div>
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            Total
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-1.5 whitespace-nowrap">
                {totalBalance && totalBalance.map((balance) =>
                    <div key={balance.currency.id} className="flex pt-0.5">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">{balance.currency.symbol + ' ' + balance.amount}</span>
                    </div>
                )}
            </td>
        </tr>
    );
};

export default AccountTotalBalanceTableItem;