import {useState} from "react";
import AuthService from "../api/AuthService";
import {authSlice} from "../store/reducers/AuthSlice";
import {useAppDispatch} from "../hooks/redux";

const SignInPage = () => {
    const {setAuth, clearAuth} = authSlice.actions;
    const dispatch = useAppDispatch();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null as any);

    async function login(event: any) {
        event.preventDefault();
        try {
            const authData = await AuthService.login(username, password);
            const user = {id: authData.id, username: authData.username, signOutCallback: null};
            dispatch(setAuth({user: user, token: authData.token}));
        } catch (ex) {
            setErrorMessage("Login or password is incorrect")
        }
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    {/*<img*/}
                    {/*    className="mx-auto h-10 w-auto"*/}
                    {/*    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=900"*/}
                    {/*    alt="Your Company"*/}
                    {/*/>*/}
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" action="#" method="POST">
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                Username
                            </label>
                            <div className="mt-2">
                                <input
                                    id="username"
                                    name="username"
                                    type="text"
                                    autoComplete="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="text-sm">
                                    {/*<a href="#" className="font-semibold text-indigo-900 hover:text-indigo-800">*/}
                                    {/*    Forgot password?*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {errorMessage && <div className="text-red-600">
                            {errorMessage}
                        </div>}

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                onClick={login}
                            >
                                Sign in
                            </button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member?{' '}
                        <a href="#" className="font-semibold leading-6 text-indigo-900 hover:text-indigo-800">
                            Sign Up
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
}

export default SignInPage;