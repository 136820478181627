import React, {Fragment, useMemo} from 'react';
import {Listbox, Transition} from "@headlessui/react";
import {classNames} from "../../../utils/common";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/solid";

const CurrencyPicker = ({label, currency, setCurrency, currencies}) => {
    const selectorCurrencyWidth = useMemo(() => {
        let maxWidth = 1;
        currencies.forEach(currency => {
            let currencyWidth = currency.name.length * 8;
            if (currencyWidth > maxWidth) {
                maxWidth = currencyWidth;
            }
        });
        return maxWidth + 100 + 'px';
    }, [currencies]);

    return (
        <Listbox as="div" value={currency || ''} onChange={setCurrency} className="mt-5 mx-10">
            {({ open }) => (
                <>
                    <Listbox.Label className="ml-3 block text-sm font-medium text-gray-700"> {label}</Listbox.Label>
                    <div className="mt-1 relative h-10">
                        <Listbox.Button className="relative bg-white border border-gray-300 rounded-md shadow-sm h-10 pl-3 pr-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                                        style={{minWidth: selectorCurrencyWidth}}>
                            <span className="flex justify-between">
                                <span className="flex items-center block">
                                    {currency && <span className="ml-3 block whitespace-nowrap">{currency.name}</span>}
                                    {/*{currency && <span className="ml-3 block whitespace-nowrap">{currency.symbol}</span>}*/}
                                    {!currency && <span className="ml-3 block whitespace-nowrap">No available currency</span>}
                                </span>
                                <span className="ml-3 block flex items-center pointer-events-none">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </span>
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {currencies.map((currencyOption) => (
                                    <Listbox.Option
                                        key={currencyOption.id}
                                        className={({ active }) =>
                                            classNames(active
                                                    ? 'bg-indigo-600 text-white'
                                                    : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9')
                                        }
                                        value={currencyOption}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', active ? 'text-gray-300' : 'text-gray-500', 'sm:text-sm')}>
                                                        {currencyOption.code}
                                                    </span>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                        {currencyOption.name}
                                                    </span>
                                                </div>
                                                {selected && <span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                        </span>}
                                            </>
                                        )}
                                    </Listbox.Option>)
                                )}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>)}
        </Listbox>
    );
};

export default CurrencyPicker;