import React from 'react';
import {classNames, isTodayDate} from "../../../utils/common";

const RowDatePickerCell = ({dateItem, setDate, isCurrentDate}) => {
    return (
        <li className={classNames("inline-block w-16 h-16 border align-middle cursor-pointer hover:bg-gray-200 first:rounded-l-md last:rounded-r-md",
                isTodayDate(dateItem.date) ? 'border-gray-500 rounded border-b-2' : 'border-gray-300',
                isCurrentDate && 'border-indigo-500 rounded border-b-2')}
            onClick={() => setDate(dateItem.date)}>
            <div className="relative text-center pt-2 font-medium text-gray-500 z-30">
                {dateItem.weekDay}
            </div>
            <div className="relative text-center pb-2 font-bold z-30">
                {dateItem.day}
            </div>
        </li>
    );
};

export default RowDatePickerCell;