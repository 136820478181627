import React from 'react';

const AccountField = ({label, accountName, setAccountName}) => {
    return (
        <div className="mt-5 mx-8">
            <label htmlFor="accountName" className="ml-3 block text-sm font-medium text-gray-700">{label}</label>
            <div className="mt-1 relative rounded-md shadow-sm h-10">
                <input value={accountName} onChange={(event) => setAccountName(event.target.value)} type="text" id="accountName"
                       className="focus:ring-indigo-500 focus:border-indigo-500 block w-60 px-4 sm:text-sm border-gray-300 rounded-md"/>
            </div>
        </div>
    );
};

export default AccountField;