import React from 'react';
import Loader from "../ui/Loader";
import AccountBalanceTableItem from './AccountBalanceTableItem';
import useActiveAccountsSelector from "../../hooks/useActiveAccountsSelector";
import AccountTotalBalanceTableItem from "./AccountTotalBalanceTableItem";

const AccountsBalanceTable = () => {
    const {activeAccounts: accounts, isLoading, error} = useActiveAccountsSelector();

    return (
        <>
            {isLoading && <div className="flex justify-center"><Loader></Loader></div>}
            {error && <div>Error occurred during fetching Accounts: {error.toString()}</div>}
            <table className="sticky top-20 min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Account
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Balance
                    </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {accounts && accounts.map((account) =>
                        <AccountBalanceTableItem key={account.id} {...account}/>
                    )}
                    <AccountTotalBalanceTableItem/>
                </tbody>
            </table>
        </>
    );
};

export default AccountsBalanceTable;