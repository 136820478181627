import React, {Fragment} from 'react';
import {Menu, Transition} from "@headlessui/react";
import {UserCircleIcon} from "@heroicons/react/24/outline";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {authSlice} from "../../store/reducers/AuthSlice";
import {appSlice} from "../../store/reducers/AppSlice";

const UserMenu = () => {
    const user = useAppSelector(state => state.auth.user);
    const {clearAuth} = authSlice.actions;
    const {clearAppData} = appSlice.actions;
    const dispatch = useAppDispatch();

    const signOut = () => {
        dispatch(clearAuth());
        dispatch(clearAppData());
    }
    return (
        user &&
        <div className="ml-4 flex items-center">
            <Menu as="div" className="relative ml-3">
                <div>
                    <Menu.Button className="relative flex items-center text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                        <UserCircleIcon className="h-6 w-6 rounded-full text-white mr-2"/>
                        {user.username}
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                            {({ active }) => (
                                <a href='#' className={(active ? 'bg-gray-100' : '') + ' block px-4 py-2 text-sm text-gray-700'}>
                                    Your Profile
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a href='#' className={(active ? 'bg-gray-100' : '') + ' block px-4 py-2 text-sm text-gray-700'}>
                                    Settings
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a href='#' onClick={signOut} className={(active ? 'bg-gray-100' : '') + ' block px-4 py-2 text-sm text-gray-700'}>
                                    Sign out
                                </a>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};

export default UserMenu;