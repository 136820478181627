import React from 'react';
import {Route, Routes} from "react-router-dom";
import AccountsBalanceTable from "../../components/accounts/AccountsBalanceTable";
import ExpenseTransactionsPage from "../transactions/ExpenseTransactionsPage";
import IncomeTransactionsPage from "../transactions/IncomeTransactionsPage";
import TransferTransactionsPage from "../transactions/TransferTransactionsPage";
import ExchangeTransactionsPage from "../transactions/ExchangeTransactionsPage";
import TransactionTable from "../../components/transactions/TransactionTable";
import NewTransactionForm from "../../components/transactions/NewTransactionForm";

const TransactionLayout = () => {
    return (
        <>
            <div className="relative w-full mx-auto max-w-7xl py-6 px-8">
                <Routes>
                    <Route index element={<ExpenseTransactionsPage/>}/>
                    <Route path="expense" element={<ExpenseTransactionsPage/>}/>
                    <Route path="income" element={<IncomeTransactionsPage/>}/>
                    <Route path="transfer" element={<TransferTransactionsPage/>}/>
                    <Route path="exchange" element={<ExchangeTransactionsPage/>}/>
                </Routes>
                <NewTransactionForm/>
                <TransactionTable/>
                {/*{*/}
                {/*    !trType || trHooks[trType].isLoading*/}
                {/*        ? <div className="flex justify-center"><Loader></Loader></div>*/}
                {/*        : <TransactionTable trType={trType} transactions={trHooks[trType].data}></TransactionTable>*/}
                {/*}*/}
            </div>
            <div className="relative mx-auto py-6 px-8 min-w-[27%]">
                <AccountsBalanceTable/>
            </div>
        </>
    );
};

export default TransactionLayout;