import React, {useEffect, useMemo, useState} from 'react';
import {categoryApi} from "../../api/CategoriesService";
import Loader from "../ui/Loader";
import BulkUploadRowItem from "./BulkUploadRowItem";
import {IBulkUploadRow} from "../../model/IBulkUpload";
import CategoryPicker from "../ui/transaction/CategoryPicker";
import {TransactionType} from "../../model/TransactionType";
import Filter from "../ui/Filter";
import StatusFilters from "../ui/bulk_upload/StatusFilters";
import CategoryFilledCheckbox from "../ui/bulk_upload/FilledRowCheckbox";
import CommentField from "../ui/transaction/CommentField";
import {Tag} from "../../model/ICategory";
import TagsPicker from "../ui/transaction/TagsPicker";

interface SessionProps {
    session_id: string;
}

const BulkUploadRowsTable = (props: SessionProps) => {
    const session_id = props.session_id;
    const {data: sessionRows, isLoading, error, refetch: refetchSessionRows} = categoryApi.useFetchAllBulkUploadRowsQuery(session_id);
    const {data: session, isLoading: sessionLoading} = categoryApi.useGetBulkUploadSessionQuery(session_id);
    const {data: expenseCategories, isLoading: expCatLoading} = categoryApi.useFetchAllCategoriesQuery(TransactionType.Expense);
    const {data: incomeCategories, isLoading: incCatLoading} = categoryApi.useFetchAllCategoriesQuery(TransactionType.Income);
    const {data: tagsList} = categoryApi.useFetchAllTagsQuery();
    const [category, setCategory] = useState(null as any);
    const [tags, setTags] = useState([] as Tag[]);
    const [comment, setComment] = useState("");
    const [categoryType, setCategoryType] = useState(null as any);
    const [filter, setFilter] = useState('');
    const [selectFilled, setSelectFilled] = useState(true);
    const [selectedStatuses, setSelectedStatuses] = useState(['UPLOADED', 'EXCLUDED', 'CREATED',]);
    const [patchBulkUploadRows, {isLoading: isPatching}] = categoryApi.usePatchBulkUploadRowsMutation();
    const [reconcileBulkUploadSession, {isLoading: isReconciling}] = categoryApi.useReconcileBulkUploadSessionMutation();

    const [allSelected, setAllSelected] = useState(false);
    const [selected, setSelected] = useState([] as IBulkUploadRow[]);
    // const isAllSelected =
    //     options.length > 0 && selected.length === options.length;

    const handleSelectAll = (e: React.FormEvent<HTMLInputElement>) => {
        const isAllSelected = e.currentTarget.checked;
        console.log("handleSelectAll" + isAllSelected);

        if (allSelected) {
            setSelected([]);
            setAllSelected(false);
        } else {
            if (filteredRows.length === 0) {
                alert('No rows to select');
                return;
            }
            if (!filteredRows.every((row) => row.amount < 0) && !filteredRows.every((row) => row.amount > 0)) {
                alert('All rows should be the same category type');
                return;
            }
            setSelected(filteredRows);
            setAllSelected(true);
            setCategoryType(filteredRows[0].amount < 0 ? TransactionType.Expense : TransactionType.Income);
        }
    };

    const handleCategoryRowChange = (e: React.FormEvent<HTMLInputElement>) => {
        const categoryId = e.currentTarget.value;
        console.log("handleCategoryRowChange: " + categoryId);
        // if (categoryId === allTitle) {
        //     setSelected(selected.length === options.length ? [] : options);
        //     return;
        // }
        if (!sessionRows) {
            return;
        }

        const list = [...selected];
        const rowToUnselect = list.find(row => row.id.toString() === categoryId)
        const deleteRowIndex = rowToUnselect ? list.indexOf(rowToUnselect) : -1;
        if (deleteRowIndex === -1) {
            const rowToSelect = sessionRows.find(row => row.id.toString() === categoryId)
            // @ts-ignore
            if (rowToSelect.amount < 0 && TransactionType.Income === categoryType || rowToSelect.amount >= 0 && TransactionType.Expense === categoryType) {
                alert("Please choose row with the same category type Expense/Income");
                return;
            }
            // @ts-ignore
            list.push(rowToSelect);
            if (!categoryType) {
                // @ts-ignore
                setCategoryType(rowToSelect.amount < 0 ? TransactionType.Expense : TransactionType.Income);
            }
        } else {
            list.splice(deleteRowIndex, 1);
            if (list.length === 0) {
                setCategoryType(null);
            }
        }
        setSelected(list);
    };

    const filteredRows = useMemo(() => {
        if (!sessionRows) {
            return [];
        } else {
            const filterInUpper = filter.toUpperCase();
            return [...sessionRows].filter((row) =>
                selectedStatuses.includes(row.status)
                    && (selectFilled || !row.category)
                    && (filter === ''
                        || row.currency_code.toUpperCase().includes(filterInUpper)
                        || row.description.toUpperCase().includes(filterInUpper)
                        || row.category_description && row.category_description.toUpperCase().includes(filterInUpper)));
        }
    }, [sessionRows, filter, selectedStatuses, selectFilled]);

    useEffect(() => {
        setSelected([]);
        setAllSelected(false);
    }, [filteredRows]);

    const handleBulkUpdate = (e: React.MouseEvent<HTMLElement>) => {
        console.log(selected);
        if (!category) {
            alert("Please choose Category");
            return;
        }
        if (selected.length === 0) {
            alert("Please choose at least one row");
            return;
        }
        patchBulkUploadRows(selected.map(selectedRow => ({id: selectedRow.id, category: category.id, comment: comment, tag_ids: tags.map(tag => tag.id)})));

        setSelected([]);
    }

    const handleReconcile = (e: React.MouseEvent<HTMLElement>) => {
        console.log('Call Recons with session_id: '+ session_id);
        reconcileBulkUploadSession(session_id);
        refetchSessionRows();
    }

    return (
        <>
            <div className="sticky top-16 bg-gray-50 rounded-md z-40">
                <div className="mx-10 my-2 px-6 pt-4 pb-2 flex justify-evenly items-end">
                    {/*<button className="mx-10 group relative flex justify-center py-2 px-6 border border-transparent*/}
                    {/*                   text-sm font-medium rounded-md bg-gray-200 text-red-500 hover:text-red-800"*/}
                    {/*        onClick={clearForm}>*/}
                    {/*    Clear*/}
                    {/*</button>*/}
                    <div className="flex justify-center items-end">
                        <CategoryPicker label={"Category"} category={category} setCategory={setCategory}
                                        categories={categoryType ? TransactionType.Expense === categoryType ? expenseCategories : incomeCategories : []}
                                        className="mx-4"/>
                        <CommentField label={"Comment"} comment={comment} setComment={setComment} className="mx-4"/>
                        <TagsPicker label="Tags" tags={tags} setTags={setTags} tagsList={tagsList ? tagsList : []} className="mx-4"/>
                        <button className="mx-10 h-10 group relative flex justify-center py-2 px-6 border border-transparent
                                           text-sm font-medium rounded-md bg-blue-100 text-blue-800 hover:bg-blue-200 focus:outline-none"
                                disabled={isLoading}
                                onClick={handleBulkUpdate}>
                            Bulk Update
                        </button>
                    </div>
                    <button className="mx-10 my-1 h-10 group relative flex justify-center items-center py-2 px-6 border border-transparent
                                       text-sm font-medium rounded-md bg-green-100 text-green-800 hover:bg-green-200 focus:outline-none"
                            disabled={isReconciling}
                            onClick={handleReconcile}>
                        Reconcile
                    </button>
                </div>

                <hr className="gray-900"/>

                <div className="mx-10 my-2 px-6 pt-2 pb-4 flex items-end justify-evenly">
                    <Filter filter={filter} setFilter={setFilter}/>
                    <CategoryFilledCheckbox selectFilled={selectFilled} setSelectFilled={setSelectFilled} className="m-2"/>
                    <StatusFilters selectedStatuses={selectedStatuses} setSelectedStatuses={setSelectedStatuses} className="m-2"/>
                </div>
            </div>

            {isLoading && <div className="flex justify-center"><Loader></Loader></div>}
            {error && <div>Error occurred during fetching Accounts: {error.toString()}</div>}
            <table className="min-w-full divide-y divide-gray-200 border-b-2 border-gray-400">
                <thead className="sticky top-64 bg-gray-50 z-30">
                <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex h-6 items-center">
                            <input
                                id="checkbox_all"
                                name="checkbox_all"
                                type="checkbox"
                                checked={allSelected}
                                onChange={handleSelectAll}
                                className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                        </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        C/M
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Comment
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tags
                    </th>
                    {/* Save */}
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"/>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                    </th>
                    {/* Toggle status */}
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"/>
                </tr>
                </thead>
                {!isLoading && (!filteredRows || filteredRows.length === 0) && <tbody>
                <tr className="font-bold text-xl text-gray-700">
                    <td colSpan={5}><span className="m-2">
                    {filter === '' && selectedStatuses.length !== 0 ? 'Session has no transaction records' : 'Session has no such transaction records. Please consider to change filtration'}
                </span></td>
                </tr>
                </tbody>}
                {filteredRows && filteredRows.length !== 0 && <tbody className="bg-white divide-y divide-gray-200">
                {filteredRows.map((row) =>
                    <BulkUploadRowItem key={row.id} row={row} handleCategoryChange={handleCategoryRowChange} checked={selected.includes(row)}
                                       accountCurrencySymbol={session?.currency_symbol}/>
                )}
                </tbody>}
            </table>
        </>
    );
};

export default BulkUploadRowsTable;