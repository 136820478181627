import React from 'react';
import {IAccount} from "../../model/IAccount";
import {classNames} from "../../utils/common";
import {accountApi} from "../../api/AccountsService";

const AccountItem: React.FunctionComponent<IAccount> = (account) => {
    const [setAccountStatus, {isLoading}] = accountApi.useSetAccountStatusMutation();

    const handleSetAccountStatus = () => {
        setAccountStatus({id: account.id, is_active: !account.is_active});
    }

    return (
        <tr>
            <td className="px-4 py-2 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                        <img className="h-10 w-10 rounded-full" src={`${process.env.PUBLIC_URL}/images/acc_logos/` + account.logo} alt=""/>
                    </div>
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            {account.name}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                    {account.initial_balance}
                </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {account.balance}
                </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span className={classNames(
                    account.is_active ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800",
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full")}
                >
                    {account.is_active ? 'Active' : 'Disabled'}
                </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button className="cursor-pointer text-indigo-600 hover:text-indigo-900">Edit</button>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button className="cursor-pointer text-red-600 hover:text-red-900"
                        disabled={isLoading}
                        onClick={handleSetAccountStatus}
                >
                    {account.is_active ? 'Disable' : 'Enable'}
                </button>
            </td>
        </tr>
    );
};

export default AccountItem;