import React, {useEffect, useMemo, useState} from 'react';
import {useAppSelector} from "../../hooks/redux";
import WeekRowDatePicker from "../ui/date_picker/WeekRowDatePicker";
import {TransactionType} from "../../model/TransactionType";
import {ChevronDoubleRightIcon} from "@heroicons/react/24/solid";
import AccountPicker from "../ui/transaction/AccountPicker";
import CategoryPicker from "../ui/transaction/CategoryPicker";
import AmountField from "../ui/transaction/AmountField";
import CommentField from "../ui/transaction/CommentField";
import TagsPicker from "../ui/transaction/TagsPicker";
import {categoryApi} from "../../api/CategoriesService";
import {transactionApi} from "../../api/TransactionService";
import {ICategoryTransactionReq, IExchangeTransactionReq, ITransactionReq, ITransferTransactionReq} from "../../model/ITransaction";
import {Tag} from "../../model/ICategory";
import {trunc} from "../../utils/common";
import useActiveAccountsSelector from "../../hooks/useActiveAccountsSelector";
import useTrTypesSelector from "../../hooks/useTrTypesSelector";

const NewTransactionForm = () => {
    const trType = useAppSelector(state => state.app.trType);
    const {isCategoryType, isExchangeType, isTransferOrExchangeType} = useTrTypesSelector(trType);

    const {activeAccounts: accounts} = useActiveAccountsSelector();
    const {data: categories} = categoryApi.useFetchAllCategoriesQuery(trType, {skip: isTransferOrExchangeType});
    const {data: tagsList} = categoryApi.useFetchAllTagsQuery();

    const [date, setDate] = useState(new Date());
    const [account, setAccount] = useState(null as any);
    const [amount, setAmount] = useState('' as any);

    const [category, setCategory] = useState(null as any);
    const [comment, setComment] = useState('');
    const [tags, setTags] = useState([] as Tag[]);

    const [accountTo, setAccountTo] = useState(null as any);
    const [amountTo, setAmountTo] = useState(0.00);

    const accountsTo = useMemo(() => {
        if (!accounts) {
            return [];
        }
        return accounts.filter(acc => {
            if (!account) {
                return false;
            }
            // return acc.currency.id === account.currency.id && acc !== account;
            return (isExchangeType && acc.currency.id !== account.currency.id)
                || (!isExchangeType && acc !== account && acc.currency.id === account.currency.id)
        })
    }, [account]);

    function clearForm() {
        setAmount('');
        setComment('');
    }

    const [addNewTransaction, {isLoading}] = transactionApi.useCreateTransactionMutation();

    function composeNewTransaction() : ITransactionReq {
        let transaction : ITransactionReq = {
            account: account.id,
            amount: amount,
            comment: comment,
            date: trunc(date)
        };
        switch (trType) {
            case TransactionType.Expense:
            case TransactionType.Income: {
                let categoryTransaction = transaction as ICategoryTransactionReq;
                categoryTransaction.category = category.id;
                categoryTransaction.tag_ids = tags.map((tag) => tag.id);
                return categoryTransaction;
            }
            case TransactionType.Transfer: {
                let transferTransaction = transaction as ITransferTransactionReq;
                transferTransaction.account_to = accountTo.id;
                return transferTransaction;
            }
            case TransactionType.Exchange: {
                let exchangeTransaction = transaction as IExchangeTransactionReq;
                exchangeTransaction.account_to = accountTo.id;
                exchangeTransaction.amount_to = amountTo;
                return exchangeTransaction;
            }
        }
    }

    async function handleAddNewTransaction() {
        const result = await addNewTransaction({
            trType: trType,
            transaction: composeNewTransaction(),
        });
        if ('data' in result) {
            clearForm();
        } else {
            console.log(result.error);
        }
    }

    useEffect(() => {
        if (accounts && !account) {
            setAccount(accounts[0]);
        }
    }, [accounts]);

    useEffect(() => {
        if (categories) {
            setCategory(categories[0]);
        }
    }, [categories]);

    useEffect(() => {
        if (!accountTo || !accountsTo.includes(accountTo)) {
            setAccountTo(accountsTo[0]);
        }
    }, [accountsTo]);

    return (
        <div id="transaction" className="mb-4 border-b border-gray-200">
            <WeekRowDatePicker date={date} setDate={setDate}/>

            <div className="flex justify-center mx-6 px-6 z-50">
                {account && <AccountPicker label={isCategoryType ? 'Account' : 'Account From'} account={account} setAccount={setAccount} accounts={accounts} className="mt-5 mx-10 z-50" />}

                {isTransferOrExchangeType && <div className="flex items-end">
                    <ChevronDoubleRightIcon className="h-8 w-8 text-gray-600"></ChevronDoubleRightIcon>
                </div>}

                {isTransferOrExchangeType && <AccountPicker label="Account To" account={accountTo} setAccount={setAccountTo} accounts={accountsTo} className="mt-5 mx-10 z-50"/>}

                {isCategoryType && category && <CategoryPicker label="Category" category={category} setCategory={setCategory} categories={categories} className="mt-5 mx-10 z-50"/>}
            </div>

            <div className="flex justify-center mx-6 px-1">
                <AmountField label={isExchangeType ? 'Amount From' : 'Amount'} currency={account?.currency} amount={amount} setAmount={setAmount}/>
                {isExchangeType && <AmountField label="Amount To" currency={accountTo?.currency} amount={amountTo} setAmount={setAmountTo}/>}

                {isCategoryType && <CommentField comment={comment} setComment={setComment} className={"mt-5 mx-8"}/>}
                {isCategoryType && tagsList && tagsList.length !== 0 && <TagsPicker label="Tags" tags={tags} setTags={setTags} tagsList={tagsList} className="mt-5 mx-8 z-40"/>}
            </div>

            <div className="mx-10 my-4 flex items-center justify-center">
                <button className="mx-10 group relative flex justify-center py-2 px-6 border border-transparent
                                   text-sm font-medium rounded-md bg-gray-200 text-red-500 hover:text-red-800"
                        onClick={clearForm}>
                    Clear
                </button>
                <button className="mx-10 group relative flex justify-center py-2 px-6 border border-transparent
                                   text-sm font-medium rounded-md bg-green-100 text-green-800 hover:bg-green-200 focus:outline-none"
                        disabled={isLoading}
                        onClick={handleAddNewTransaction}>
                    Add
                </button>
            </div>
        </div>
    );
};

export default NewTransactionForm;