class ApiUrls {
    static GET_ALL_ACCOUNTS_URL = "http://localhost:8000/api/v1/accounts/account/";
    static GET_EXPENSE_TRANSACTIONS_URL = "http://localhost:8000/api/v1/transactions/expense/";
    static GET_INCOME_TRANSACTIONS_URL = "http://localhost:8000/api/v1/transactions/income/";
    static GET_TRANSFER_TRANSACTIONS_URL = "http://localhost:8000/api/v1/transactions/transfer/";
    static GET_EXCHANGE_TRANSACTIONS_URL = "http://localhost:8000/api/v1/transactions/exchange/";
}

export const getBaseUrl = () => {
    return window.location.origin;
}

export default ApiUrls;
