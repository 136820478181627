import React from 'react';

const SimpleDatePicker = ({date, setDate, ...props}) => {
    function handleDateChange(e) {
        setDate(e.target.value);
    }

    return (
        <div {...props}>
            <input value={date} onChange={handleDateChange} type="date"
                    className="bg-white border border-gray-300 rounded-md shadow-sm sm:text-sm"/>
        </div>
    );
};

export default SimpleDatePicker;