import React, {useState} from 'react';
import {ChevronDoubleRightIcon} from "@heroicons/react/24/solid";
import {transactionApi} from "../../api/TransactionService";
import EditTransactionForm from "./EditTransactionForm";
import {Transition} from "@headlessui/react";
import useTrTypesSelector from "../../hooks/useTrTypesSelector";

const TransactionTableItem = ({trType, transaction, readOnly = false}) => {
    const [editMode, setEditMode] = useState(false);
    const {isCategoryType, isExchangeType, isTransferOrExchangeType} = useTrTypesSelector(trType);

    const [deleteTransaction, {isLoading: isDeleting}] = transactionApi.useDeleteTransactionMutation();

    function toggleEditMode() {
        setEditMode(!editMode);
    }

    return (
        <>
        <Transition
            show={!editMode}
            as="tr"
            enter="ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            className={editMode ? "absolute" : ""}>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                        <img className="h-10 w-10 rounded-full" src={`${process.env.PUBLIC_URL}/images/acc_logos/` + transaction.account_logo} alt=""/>
                    </div>
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            { transaction.account_name }
                        </div>
                    </div>
                </div>
            </td>
            {isCategoryType && <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">{ transaction.category_name }</div>
            </td>}
            <td className="px-6 py-4 whitespace-nowrap">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    { transaction.account_currency_symbol + ' ' + transaction.amount }
                </span>
            </td>
            {isCategoryType && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                { (transaction.comment ? transaction.comment + ' ' : '') + (transaction.tags && transaction.tags.map(tag => '[' + tag.name + ']').join(' '))}
            </td>}
            {isTransferOrExchangeType && <td className="px-6 py-4 whitespace-nowrap">
                <ChevronDoubleRightIcon className="h-4 w-4"/>
            </td>}
            {isExchangeType && <td className="px-6 py-4 whitespace-nowrap">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    { transaction.account_to_currency_symbol + ' ' + transaction.amount_to }
                </span>
            </td>}
            {isTransferOrExchangeType && <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                        <img className="h-10 w-10 rounded-full" src={`${process.env.PUBLIC_URL}/images/acc_logos/` + transaction.account_to_logo} alt=""/>
                    </div>
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            { transaction.account_to_name }
                        </div>
                    </div>
                </div>
            </td>}
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                { transaction.date }
            </td>
            {!readOnly && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button onClick={toggleEditMode} className="cursor-pointer text-indigo-600 hover:text-indigo-900">Edit</button>
            </td>}
            {!readOnly && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button className="cursor-pointer text-red-600 hover:text-red-900"
                   onClick={() => {if (!isDeleting) {deleteTransaction({trType: trType, id: transaction.id})}}}
                >
                    Delete
                </button>
            </td>}
        </Transition>
            <Transition
                show={editMode}
                as="tr"
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={!editMode ? "absolute left-1/2" : ""}>
                <td colSpan={isExchangeType ? 8 : 7} className={!editMode ? "relative right-1/2" : ""}>
                    <EditTransactionForm trType={trType} transaction={transaction} close={toggleEditMode}/>
                </td>
            </Transition>
        </>
    );
};

export default TransactionTableItem;