import React from 'react';
import {IFilterStats} from "../../model/IStats";

const StatsResult: React.FunctionComponent<IFilterStats> = (filterStats) => {
    return (
        <div className="px-5 py-3 mx-5 flex items-center justify-evenly rounded-md bg-gray-100 text-gray-700">
            {filterStats.number_of_transactions && <span className="px-2 inline-flex text-m leading-5 font-semibold rounded-full text-gray-800">
                Number of transactions: <span className="px-2 font-semibold rounded-full bg-gray-200 text-red-900">{filterStats.number_of_transactions}</span>
            </span>}
            <div className="flex items-center text-m font-semibold text-gray-800">
                Sum:
                <div className="ml-4 flex flex-col">
                    {filterStats.total_amount && filterStats.total_amount.map((balance) =>
                        <div key={balance.currency.id} className="flex pt-0.5">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">{balance.currency.symbol + ' ' + balance.amount}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StatsResult;