import React, {Fragment, useMemo} from 'react';
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../../utils/common";

const TagsPicker = ({label, tags, setTags, tagsList, ...props}) => {
    const selectorTagsWidth = useMemo(() => {
        let maxWidth = 1;
        tagsList.forEach(tag => {
            let tagWidth = tag.name.length * 8;
            if (tagWidth > maxWidth) {
                maxWidth = tagWidth;
            }
        });
        return maxWidth + 50 + 'px';
    }, [tagsList]);

    const tagsString = useMemo(() => tags.map(tag => '[' + tag.name + ']').join(' '), [tags]);

    return (
        <Listbox as="div" value={tags} onChange={setTags} multiple {...props}>
            {({ open }) => (
                <>
                    {label && <Listbox.Label className="ml-3 block text-sm font-medium text-gray-700">{label}</Listbox.Label>}
                    <div className="mt-1 relative h-10">
                        <Listbox.Button className="relative bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        style={{minWidth: selectorTagsWidth}}>
                            <span className="flex items-center justify-between">
                                <span className="ml-3 block whitespace-nowrap">{tagsString}</span>
                                <span className="ml-3 flex items-center pointer-events-none">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </span>
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base
                                                        ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {tagsList.map((tagOption) => (
                                    <Listbox.Option
                                        key={tagOption.id}
                                        className={({ active }) =>
                                            classNames(active
                                                    ? 'bg-indigo-600 text-white'
                                                    : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9')
                                        }
                                        value={tagOption}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                        {tagOption.name}
                                                    </span>
                                                </div>
                                                {selected && <span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                </span>}
                                            </>
                                        )}
                                    </Listbox.Option>)
                                )}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>)}
        </Listbox>
    );
};

export default TagsPicker;