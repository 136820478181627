import React, {useEffect, useState} from 'react';

const StatusFilters = ({selectedStatuses, setSelectedStatuses, withLabel = true, ...props}) => {
    const [uploaded, setUploaded] = useState(true);
    const [excluded, setExcluded] = useState(true);
    const [created, setCreated] = useState(true);

    useEffect(() => {
        const statuses = [];
        if (uploaded) {
            statuses.push('UPLOADED');
        }
        if (excluded) {
            statuses.push('EXCLUDED');
        }
        if (created) {
            statuses.push('CREATED');
        }
        setSelectedStatuses(statuses);
    }, [uploaded, excluded, created]);

    const handleSelectUploaded = (e) => {
        setUploaded((uploaded) => !uploaded);
    };

    const handleSelectExcluded = (e) => {
        setExcluded(!excluded);
    };

    const handleSelectCreated = (e) => {
        setCreated(!created);
    };
    return (
        <div {...props}>
            {withLabel && <label htmlFor="filter" className="ml-3 mb-1 block text-sm font-medium text-gray-700">Statuses</label>}
            <div className="flex">
                <div className="flex h-6 items-center">
                    <input
                        id="checkbox_all"
                        name="checkbox_all"
                        type="checkbox"
                        checked={uploaded}
                        onChange={handleSelectUploaded}
                        className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <div className="mx-2">Uploaded</div>
                </div>
                <div className="flex h-6 items-center">
                    <input
                        id="checkbox_all"
                        name="checkbox_all"
                        type="checkbox"
                        checked={excluded}
                        onChange={handleSelectExcluded}
                        className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <div className="mx-2">Excluded</div>
                </div>
                <div className="flex h-6 items-center">
                    <input
                        id="checkbox_all"
                        name="checkbox_all"
                        type="checkbox"
                        checked={created}
                        onChange={handleSelectCreated}
                        className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <div className="mx-2">Created</div>
                </div>
            </div>
        </div>
    );
};

export default StatusFilters;