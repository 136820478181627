import React from 'react';
import UserMenu from "./UserMenu";
import {NavLink} from "react-router-dom";

const navigation = [
    {name: 'Expense', href: '/transactions/expense', current: true},
    {name: 'Income', href: '/transactions/income', current: false},
    {name: 'Transfer', href: '/transactions/transfer', current: false},
    {name: 'Exchange', href: '/transactions/exchange', current: false},
    {name: 'Accounts', href: '/accounts', current: false},
    {name: 'Model', href: '/model', current: false},
    {name: 'Bulk Upload', href: '/bulk_upload/sessions', current: false},
    {name: 'Stats', href: '/stats', current: false},
]

const getNavLinkClass = ({isActive}: {isActive: boolean}) => (isActive
        ? 'bg-gray-900 text-white'
        : 'text-gray-300 hover:bg-gray-700 hover:text-white')
    + ' rounded-md px-3 py-2 text-sm font-medium';

const Navbar = ({...props}) => {
    return (
        <div {...props}>
            <nav className="bg-gray-800">
                <div className="mx-auto max-w-7xl px-8">
                    <div className="flex h-16 items-center justify-between">
                        <div className="flex items-center">
                            <div className="">
                                <a href="https://yggllen.by">
                                    <img className="h-8 w-8" src={`${process.env.PUBLIC_URL}/images/justLogo.png`} alt=""/>
                                </a>
                            </div>
                            <div className="ml-10 flex items-baseline space-x-4">
                                {navigation.map(item =>
                                    <NavLink
                                        key={item.name}
                                        to={item.href}
                                        className={getNavLinkClass}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </NavLink>
                                )}
                            </div>
                        </div>
                        <UserMenu/>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;