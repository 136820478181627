import React, {Fragment} from "react";
import {Route, Routes} from "react-router-dom";
import TransactionLayout from "./pages/layouts/TransactionLayout";
import NotFoundPage from "./pages/NotFoundPage";
import BaseLayout from "./pages/layouts/BaseLayout";
import SignInPage from "./pages/SignInPage";
import ProfilePage from "./pages/ProfilePage";
import {useAppSelector} from "./hooks/redux";
import BulkUploadPage from "./pages/BulkUploadPage";
import AccountsPage from "./pages/AccountsPage";
import BulkUploadSessionPage from "./pages/BulkUploadSessionPage";
import StatsPage from "./pages/StatsPage";

// import ModelLayout from "./pages/layouts/ModelLayout";

function App() {
    const user = useAppSelector(state => state.auth.user);

    return (
        <>
            <Routes>
                <Route path="/" element={<BaseLayout/>}>
                    {user
                        ? <Fragment>
                            <Route path="*" element={<NotFoundPage/>}/>
                            <Route path="transactions/*" element={<TransactionLayout/>}/>
                            <Route path="accounts" element={<AccountsPage/>}/>
                            <Route path="profile" element={<ProfilePage/>}/>
                            <Route path="stats" element={<StatsPage/>}/>
                            <Route path="bulk_upload/sessions" element={<BulkUploadPage/>}/>
                            <Route path="bulk_upload/sessions/:id" element={<BulkUploadSessionPage/>}/>
                        </Fragment>
                        : <Route path="*" element={<SignInPage/>}/>}
                </Route>
            </Routes>
        </>
    );
}

export default App;
