import React, {useMemo, useState} from 'react';
import TransactionTableItem from "./TransactionTableItem";
import {useAppSelector} from "../../hooks/redux";
import Loader from "../ui/Loader";
import {transactionApi} from "../../api/TransactionService";
import useTrTypesSelector from "../../hooks/useTrTypesSelector";
import Filter from "../ui/Filter";
import PaginationBar from "../ui/PaginationBar";


const TransactionTable = () => {
    const trType = useAppSelector(state => state.app.trType);
    const {isCategoryType, isExchangeType, isTransferOrExchangeType} = useTrTypesSelector(trType);
    const [filter, setFilter] = useState('');

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const {data: trPages, isLoading, error}
        = transactionApi.useFetchAllTransactionsQuery({trType: trType, page: page, limit: pageSize});

    const transactions = useMemo(() => trPages?.results, [trPages]);
    const itemsCount = useMemo(() => trPages ? trPages.count : 0, [trPages]);

    const filteredRows = useMemo(() => {
        if (!transactions) {
            return [];
        } else {
            const filterInUpper = filter.toUpperCase();
            return [...transactions].filter((row) =>
                filter === ''
                    || row.comment && row.comment.toUpperCase().includes(filterInUpper)
                    || row.account_name && row.account_name.toUpperCase().includes(filterInUpper)
                    // @ts-ignore
                    || row.account_to_name && row.account_to_name.toUpperCase().includes(filterInUpper)
                    // @ts-ignore
                    || row.category_name && row.category_name.toUpperCase().includes(filterInUpper));
        }
    }, [transactions, filter,]);

    return (
        <>
            <div className="bg-white px-4 py-3 flex items-center border-t border-gray-200 sm:px-6">
                <div className="flex items-center">
                    <p className="text-sm text-gray-700 mr-2">
                        Filter
                    </p>
                    <Filter filter={filter} setFilter={setFilter} withLabel={false} wideField={false}/>
                </div>
                <div className="w-full ml-10">
                    <PaginationBar page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} itemsCount={itemsCount}/>
                </div>
            </div>
            <div id="transactions" className="flex flex-col">
            {/*<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">*/}
                {/*<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">*/}
                    {/*<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">*/}
                        {isLoading && <div className="flex justify-center"><Loader></Loader></div>}
                        {error && <div>Error occurred during fetching Accounts: {error.toString()}</div>}
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="sticky top-16 bg-gray-50 z-30">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {isCategoryType ? 'Account' : 'Account From'}
                                </th>
                                {isCategoryType && <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Category
                                </th>}
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    {isExchangeType ? 'Amount From' : 'Amount'}
                                </th>
                                {isCategoryType && <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Comment
                                </th>}
                                {isTransferOrExchangeType && <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {/* Empty column header for arrow */}
                                </th>}
                                {isExchangeType && <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Amount To
                                </th>}
                                {isTransferOrExchangeType && <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Account To
                                </th>}
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                    <span className="sr-only">Edit</span>
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                    <span className="sr-only">Delete</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {filteredRows && filteredRows.map((transaction) =>
                                <TransactionTableItem
                                    key={transaction.id}
                                    transaction={transaction}
                                    trType={trType}/>
                            )}
                            </tbody>
                        </table>
                        {filteredRows && !isLoading && filteredRows.length === 0 && <div className="flex justify-center text-xl text-gray-700">No transactions</div>}
                    </div>
            <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
                <PaginationBar page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} itemsCount={itemsCount}/>
            </div>
        </>
    );
};

export default TransactionTable;