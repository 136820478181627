import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ITransactionReq, ITransactionResp} from "../model/ITransaction";
import {getAuthToken} from "../store/reducers/AuthSlice";
import {TransactionType} from "../model/TransactionType";
import {Tags} from "./Tags";
import {accountApi} from "./AccountsService";
import {getBaseUrl} from "./ApiUrls";
import {categoryApi} from "./CategoriesService";

interface ITransactionPaginationParams {
    trType: TransactionType;
    page: number;
    limit: number;
}

interface ITransactionPaginationResponse {
    count: number;
    next: string;
    previous: string;
    results: ITransactionResp[];
}


export const transactionApi = createApi({
    reducerPath: 'transactionApi',
    tagTypes: [TransactionType.Expense, TransactionType.Income, TransactionType.Transfer, TransactionType.Exchange, Tags.Account],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl() + '/api/v1/transactions/',
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set('Authorization', token);
            }
            return headers;
        }
    }),
    endpoints: (build) => ({
        fetchAllTransactions: build.query<ITransactionPaginationResponse, ITransactionPaginationParams>({
            query: ({trType, page, limit}) => ({
                url: trType + '/',
                params: {
                    page: page,
                    limit: limit,
                },
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(accountApi.util.invalidateTags([Tags.Account]));
                dispatch(categoryApi.util.invalidateTags([Tags.AccountsTotalBalance]));
            },
            providesTags: [TransactionType.Expense, TransactionType.Income, TransactionType.Transfer, TransactionType.Exchange]
        }),
        createTransaction: build.mutation<ITransactionResp, {trType: TransactionType, transaction: ITransactionReq}>({
            query: ({trType, transaction}) => ({
                url: trType + '/',
                method: 'POST',
                body: transaction,
            }),
            invalidatesTags: [TransactionType.Expense, TransactionType.Income, TransactionType.Transfer, TransactionType.Exchange]
        }),
        updateTransaction: build.mutation<ITransactionResp, {trType: TransactionType, id: number, transaction: ITransactionReq}>({
            query: ({trType, id, transaction}) => ({
                url: trType + '/' + id + '/',
                method: 'PUT',
                body: transaction,
            }),
            invalidatesTags: [TransactionType.Expense, TransactionType.Income, TransactionType.Transfer, TransactionType.Exchange]
        }),
        deleteTransaction: build.mutation<void, {trType: TransactionType, id: number}>({
            query: ({trType, id}) => ({
                url: trType + '/' + id + '/',
                method: 'DELETE',
            }),
            invalidatesTags: [TransactionType.Expense, TransactionType.Income, TransactionType.Transfer, TransactionType.Exchange]
        }),
    }),
});