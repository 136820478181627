import React from 'react';
import {IAccount} from "../../model/IAccount";

const AccountBalanceTableItem: React.FunctionComponent<IAccount> = (account) => {
    return (
        <tr>
            <td className="px-4 py-2 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                        <img className="h-10 w-10 rounded-full" src={`${process.env.PUBLIC_URL}/images/acc_logos/` + account.logo} alt=""/>
                    </div>
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            {account.name}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {account.currency.symbol + ' ' + account.balance}
                </span>
            </td>
        </tr>
    );
};

export default AccountBalanceTableItem;