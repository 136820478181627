import React from 'react';
import {categoryApi} from "../../api/CategoriesService";
import Loader from "../ui/Loader";
import BulkUploadSessionItem from "./BulkUploadSessionItem";

const BulkUploadSessionsTable = () => {
    const {data: sessions, isLoading, error} = categoryApi.useFetchAllBulkUploadSessionsQuery();

    return (
        <>
            {isLoading && <div className="flex justify-center"><Loader></Loader></div>}
            {error && <div>Error occurred during fetching Accounts: {error.toString()}</div>}
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Account
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Rows
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Processed
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Comment
                    </th>
                    {/* Edit */}
                    {/*<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"/>*/}
                    {/* Toggle status */}
                    {/*<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"/>*/}
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {!isLoading && (!sessions || sessions.length === 0) && <tr className="font-bold text-xl text-gray-700"><td colSpan={5}><span className="m-2">No Bulk Upload Session records yet</span></td></tr>}
                {sessions && sessions.map((session) =>
                    <BulkUploadSessionItem key={session.id} {...session}/>
                )}
                </tbody>
            </table>
        </>
    );
};

export default BulkUploadSessionsTable;