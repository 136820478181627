import React, {useEffect} from 'react';
import {useAppDispatch} from "../hooks/redux";
import {appSlice} from "../store/reducers/AppSlice";
import AccountsTable from "../components/accounts/AccountsTable";
import NewAccountForm from "../components/accounts/NewAccountForm";

const AccountsPage = () => {
    const dispatch = useAppDispatch();
    const {setTitle} = appSlice.actions;

    useEffect(() => {
        dispatch(setTitle('Accounts'));
    });

    return (
        <div className="mx-auto py-6 px-8">
            <NewAccountForm/>
            <AccountsTable/>
        </div>
    );
};

export default AccountsPage;