import {combineReducers} from "redux";
import authReducer from "./reducers/AuthSlice";
import appReducer from "./reducers/AppSlice";
import {configureStore} from "@reduxjs/toolkit";
import {transactionApi} from "../api/TransactionService";
import {categoryApi} from "../api/CategoriesService";
import {accountApi} from "../api/AccountsService";

const rootReducer = combineReducers({
    [accountApi.reducerPath]: accountApi.reducer,
    auth: authReducer,
    app: appReducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware()
            .concat(accountApi.middleware)
            .concat(transactionApi.middleware)
            .concat(categoryApi.middleware),
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
