import React, {useEffect} from 'react';
import {useAppDispatch} from "../hooks/redux";
import {appSlice} from "../store/reducers/AppSlice";
import BulkUploadRowsTable from "../components/bulk_upload/BulkUploadRowsTable";
import {useParams} from "react-router-dom";

const BulkUploadSessionPage = () => {
    const { id } = useParams();
    const session_id = id ? id : '';
    const dispatch = useAppDispatch();
    const {setTitle} = appSlice.actions;

    useEffect(() => {
        dispatch(setTitle('Bulk Upload Session #' + session_id));
    });

    return (
        <div className="mx-auto py-6 px-8">
            {id && <BulkUploadRowsTable session_id={session_id}/>}
        </div>
    );
};

export default BulkUploadSessionPage;