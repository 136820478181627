import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../../model/IUser";
import {getCookie, removeCookie, setCookie} from 'typescript-cookie'

interface AuthState {
    user: IUser | null;
    token: string;
}

const initialState: AuthState = {
    user: localStorage.user,
    token: getAuthToken(),
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth(state, action: PayloadAction<AuthState>) {
            state.user = action.payload.user;
            state.token = action.payload.token;
            saveAuthData(state);

        },
        clearAuth(state) {
            clearAuthData(state);
        }
    }
})

function saveAuthData(authState: AuthState) {
    setCookie('access_token', authState.token);
    localStorage.user = authState.user;
}

function clearAuthData(authState: AuthState) {
    removeCookie('access_token');
    localStorage.removeItem('user');
    authState.user = null;
}

export function getAuthToken() {
    let cookie = getCookie('access_token');
    return cookie ? cookie : '';
}

export default authSlice.reducer;