import React, {useEffect, useMemo, useState} from 'react';
import {TransactionType} from "../../model/TransactionType";
import {ChevronDoubleRightIcon} from "@heroicons/react/24/solid";
import AccountPicker from "../ui/transaction/AccountPicker";
import CategoryPicker from "../ui/transaction/CategoryPicker";
import AmountField from "../ui/transaction/AmountField";
import CommentField from "../ui/transaction/CommentField";
import TagsPicker from "../ui/transaction/TagsPicker";
import {categoryApi} from "../../api/CategoriesService";
import {transactionApi} from "../../api/TransactionService";
import {
    ICategoryTransactionReq,
    ICategoryTransactionResp,
    IExchangeTransactionReq,
    IExchangeTransactionResp,
    ITransactionReq,
    ITransactionResp,
    ITransferTransactionReq,
    ITransferTransactionResp
} from "../../model/ITransaction";
import {Tag} from "../../model/ICategory";
import SimpleDatePicker from "../ui/date_picker/SimpleDatePicker";
import {Transition} from "@headlessui/react";
import useActiveAccountsSelector from "../../hooks/useActiveAccountsSelector";
import useTrTypesSelector from "../../hooks/useTrTypesSelector";

interface FormProps {
    trType: TransactionType;
    transaction: ITransactionResp;
    close: Function;
}

const EditTransactionForm : React.FunctionComponent<FormProps> = ({trType, transaction, close}) => {
    const {isCategoryType, isExchangeType, isTransferOrExchangeType} = useTrTypesSelector(trType);

    const {activeAccounts: accounts} = useActiveAccountsSelector();
    const {data: categories} = categoryApi.useFetchAllCategoriesQuery(trType, {skip: isTransferOrExchangeType});
    const {data: tagsList} = categoryApi.useFetchAllTagsQuery();

    const [date, setDate] = useState(transaction.date);
    const [account, setAccount] = useState(null as any);
    const [amount, setAmount] = useState(transaction.amount.toString() as any);

    const [category, setCategory] = useState(null as any);
    const [comment, setComment] = useState(transaction.comment ? transaction.comment : '');
    const [tags, setTags] = useState([] as Tag[]);

    const [accountTo, setAccountTo] = useState(null as any);
    const [amountTo, setAmountTo] = useState(isExchangeType ? (transaction as IExchangeTransactionResp).amount_to : 0.00);

    const accountsTo = useMemo(() => {
        if (!accounts) {
            return [];
        }
        return accounts.filter(acc => {
            if (!account) {
                return false;
            }
            return (isExchangeType && acc.currency.id !== account.currency.id)
                || (!isExchangeType && acc !== account && acc.currency.id === account.currency.id);
        })
    }, [account]);

    const [updateTransaction, {isLoading}] = transactionApi.useUpdateTransactionMutation();

    function composeUpdatedTransaction() : ITransactionReq {
        let transaction : ITransactionReq = {
            account: account.id,
            amount: amount,
            comment: comment,
            date: date,
        };
        switch (trType) {
            case TransactionType.Expense:
            case TransactionType.Income: {
                let categoryTransaction = transaction as ICategoryTransactionReq;
                categoryTransaction.category = category.id;
                categoryTransaction.tag_ids = tags.map((tag) => tag.id);
                return categoryTransaction;
            }
            case TransactionType.Transfer: {
                let transferTransaction = transaction as ITransferTransactionReq;
                transferTransaction.account_to = accountTo.id;
                return transferTransaction;
            }
            case TransactionType.Exchange: {
                let exchangeTransaction = transaction as IExchangeTransactionReq;
                exchangeTransaction.account_to = accountTo.id;
                exchangeTransaction.amount_to = amountTo;
                return exchangeTransaction;
            }
        }
    }

    async function handleUpdateTransaction() {
        const result = await updateTransaction({
            trType: trType,
            id: transaction.id,
            transaction: composeUpdatedTransaction(),
        });
        if ('data' in result) {
            close();
        } else {
            console.log(result.error);
        }
    }

    useEffect(() => {
        if (accounts && !account) {
            setAccount(accounts.filter(acc => acc.id === transaction.account)[0]);
        }
    }, [accounts]);

    useEffect(() => {
        if (categories) {
            setCategory(categories.filter(cat => cat.id === (transaction as ICategoryTransactionResp).category)[0]);
        }
    }, [categories]);

    useEffect(() => {
        if (isCategoryType && tagsList) {
            setTags(tagsList.filter(tag => (transaction as ICategoryTransactionResp).tags.some(trTag => trTag.id === tag.id)));
        }
    }, [tagsList]);

    useEffect(() => {
        if (!accountTo || !accountsTo.includes(accountTo)) {
            const trAccountTo = accountsTo.filter(acc => acc.id === (transaction as ITransferTransactionResp).account_to)[0];
            setAccountTo(trAccountTo ? trAccountTo : accountsTo[0]);
        }
    }, [accountsTo]);

    return (
        <div className="mb-4">
            <Transition.Child
                enter="ease-in-out duration-300 transform"
                enterFrom="-translate-y-full"
                enterTo="translate-y-0"
                leave="ease-in-out duration-300 transform"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-full"
                className="relative z-20 flex justify-center mx-6 px-6">
                {account && <AccountPicker label={isCategoryType ? 'Account' : 'Account From'} account={account} setAccount={setAccount} accounts={accounts} className="mt-5 mx-10"/>}

                {isTransferOrExchangeType && <div className="flex items-end">
                    <ChevronDoubleRightIcon className="h-8 w-8 text-gray-600"></ChevronDoubleRightIcon>
                </div>}

                {isTransferOrExchangeType && <AccountPicker label="Account To" account={accountTo} setAccount={setAccountTo} accounts={accountsTo} className="mt-5 mx-10"/>}

                {isCategoryType && category && <CategoryPicker label={"Category"} category={category} setCategory={setCategory} categories={categories} className={"mt-5 mx-10"}/>}
            </Transition.Child>
            <Transition.Child
                enter="ease-in-out duration-300 transform"
                enterFrom="-translate-y-full"
                enterTo="translate-y-0"
                leave="ease-in-out duration-300 transform"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-full"
                className="relative z-10 flex justify-center mx-6 px-1">
                <AmountField label={isExchangeType ? 'Amount From' : 'Amount'} currency={account?.currency} amount={amount} setAmount={setAmount}/>
                {isExchangeType && <AmountField label="Amount To" currency={accountTo?.currency} amount={amountTo} setAmount={setAmountTo}/>}

                {isCategoryType && <CommentField comment={comment} setComment={setComment} className={"mt-5 mx-8"}/>}
                {isCategoryType && tagsList && tagsList.length !== 0 && <TagsPicker label="Tags" tags={tags} setTags={setTags} tagsList={tagsList} className="mt-5 mx-8"/>}
            </Transition.Child>
            <Transition.Child
                enter="ease-in-out duration-300 transform"
                enterFrom="-translate-y-full"
                enterTo="translate-y-0"
                leave="ease-in-out duration-300 transform"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-full"
                className="relative z-0 mx-10 my-4 flex items-center justify-center">
                <SimpleDatePicker date={date} setDate={setDate}/>

                <button className="mx-10 group relative flex justify-center py-2 px-6 border border-transparent
                                   text-sm font-medium rounded-md bg-gray-200 text-red-500 hover:text-red-800"
                        onClick={() => close()}>
                    Cancel
                </button>
                <button className="mx-10 group relative flex justify-center py-2 px-6 border border-transparent
                                   text-sm font-medium rounded-md bg-green-100 text-green-800 hover:bg-green-200 focus:outline-none"
                        disabled={isLoading}
                        onClick={handleUpdateTransaction}>
                    Save
                </button>
            </Transition.Child>
        </div>
    );
};

export default EditTransactionForm;