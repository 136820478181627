import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {getAuthToken} from "../store/reducers/AuthSlice";
import {IAccount, IAccountReq} from "../model/IAccount";
import {Tags} from "./Tags";
import {getBaseUrl} from "./ApiUrls";


export const accountApi = createApi({
    reducerPath: 'accountApi',
    tagTypes: [Tags.Account],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl() + '/api/v1/accounts/account/',
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set('Authorization', token);
            }
            return headers;
        }
    }),
    endpoints: (build) => ({
        fetchAllAccounts: build.query<IAccount[], void>({
            query: () => ({
                url: '/',
            }),
            providesTags: [Tags.Account],
        }),
        createAccount: build.mutation<IAccount, IAccountReq>({
            query: (account) => ({
                url: '/',
                method: 'POST',
                body: account,
            }),
            invalidatesTags: [Tags.Account],
        }),
        setAccountStatus: build.mutation<void, {id: number, is_active: boolean}>({
            query: ({id, is_active}) => ({
                url: `/${id}/`,
                method: 'PATCH',
                body: {
                    is_active: is_active
                },
            }),
            invalidatesTags: [Tags.Account],
        }),
    }),
});