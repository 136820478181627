import React, {Fragment, useMemo} from 'react';
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../../utils/common";

const CategoriesPicker = ({label, categories, setCategories, allCategories, ...props}) => {
    function createRangeForCategoryLevel(level) {
        let keys = Array(level-1).keys();
        return [...keys];
    }

    const categoriesString = useMemo(() => categories.map(category => '[' + category.name + ']').join(' '), [categories]);

    return (
        <Listbox as="div" value={categories} onChange={setCategories} multiple {...props}>
            {({ open }) => (
                <>
                    {label && <Listbox.Label className="ml-3 block text-sm font-medium text-gray-700"> {label}</Listbox.Label>}
                    <div className="mt-1 relative h-10">
                        <Listbox.Button
                            className="relative bg-white border border-gray-300 rounded-md shadow-sm h-10 pl-3 pr-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            style={{minWidth: 250}}>
                            <span className="flex items-center justify-between">
                                <span className="ml-3 block">{categoriesString}</span>
                                <span className="ml-3 flex items-center pointer-events-none">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </span>
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {allCategories.map((categoryOption) => (
                                    <Listbox.Option
                                        key={categoryOption.id}
                                        className={({ active }) =>
                                            classNames(active
                                                    ? 'bg-indigo-600 text-white'
                                                    : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9')
                                        }
                                        value={categoryOption}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                        {createRangeForCategoryLevel(categoryOption.level).map((i) => (
                                                            <span className="h-5 w-5 inline-block" key={i}>- &nbsp;</span>
                                                        ))}
                                                        {categoryOption.name}
                                                    </span>
                                                </div>
                                                {selected && <span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                </span>}
                                            </>
                                        )}
                                    </Listbox.Option>)
                                )}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>)}
        </Listbox>
    );
};

export default CategoriesPicker;