import React from 'react';

const AmountField = ({label, currency, amount, setAmount}) => {
    return (
        <div className="mt-5 mx-8">
            <label htmlFor="amount" className="ml-3 block text-sm font-medium text-gray-700">{label}</label>
            <div className="mt-1 relative rounded-md shadow-sm h-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    {currency && <span className="text-gray-500 sm:text-sm">{currency.symbol}</span>}
                </div>
                <input value={amount} onChange={(event) => setAmount(event.target.value)} type="text" id="amount" placeholder="0.00"
                       className="focus:ring-indigo-500 focus:border-indigo-500 block w-40 pl-8 pr-12 sm:text-sm border-gray-300 rounded-md"/>
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    {currency && <span className="text-gray-500 sm:text-sm">{currency.code}</span>}
                </div>
            </div>
        </div>
    );
};

export default AmountField;