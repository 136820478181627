import React from 'react';
import TitleBar from "../components/ui/TitleBar";
import Loader from "../components/ui/Loader";

const NotFoundPage = () => {
    return (
        <>
            {/*<Navbar></Navbar>*/}
            <TitleBar title={"Not Found: 404"}></TitleBar>
            <Loader></Loader>
        </>
    );
};

export default NotFoundPage;