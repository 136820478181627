import React, {useMemo, useState} from 'react';
import {formWeekDateList, getNextWeekDate, getPreviousWeekDate, isDatesEqual} from "../../../utils/common";
import {format} from "date-fns";
import RowDatePickerCell from "./RowDatePickerCell";
import {
    ChevronDownIcon,
    ChevronUpIcon,
    HomeIcon,
    MagnifyingGlassMinusIcon,
    MagnifyingGlassPlusIcon
} from "@heroicons/react/24/solid";

const WeekRowDatePicker = ({date, setDate}) => {
    const dateList = useMemo(() => formWeekDateList(date), [date]);
    const leftMonth = useMemo(() => format(dateList[0].date, 'LLLL'), [dateList]);
    const rightMonth = useMemo(() => format(dateList[dateList.length - 1].date, 'LLLL'), [dateList]);

    const [calendarExtended, setCalendarExtended] = useState(false);

    function goToPreviousWeek() {
        setDate(getPreviousWeekDate(date));
    }

    function goToNextWeek() {
        setDate(getNextWeekDate(date));
    }

    function goToTodayDate() {
        setDate(new Date());
    }

    function toggleCalendar() {
        setCalendarExtended(!calendarExtended);
    }

    return (
        <div className="flex justify-center">
            <ul className="relative m-2">
                <div className="absolute left-0 top-0 h-full w-1/2 z-10">
                    <div className="relative flex justify-center h-full">
                        <span className="flex items-center text-center text-gray-300 text-3xl tracking-widest px-5">{leftMonth}</span>
                    </div>
                </div>
                {dateList.map((dateItem) =>
                    <RowDatePickerCell key={dateItem.date} dateItem={dateItem} setDate={setDate} isCurrentDate={isDatesEqual(date, dateItem.date)}/>
                )}
                <div className="absolute right-0 top-0 h-full w-1/2 z-10">
                    <div className="flex justify-center h-full">
                        <span className="flex items-center text-center text-gray-300 font-medium text-3xl tracking-widest px-5">{rightMonth}</span>
                    </div>
                </div>
            </ul>
            <ul className="ml-2 flex flex-col justify-center items-center">
                <li className="block border-b cursor-pointer hover:bg-gray-200 border border-gray-300 rounded-tl-md" onClick={goToPreviousWeek}>
                    <ChevronUpIcon className="p-px h-7 w-7 text-gray-400"/>
                </li>
                <li className="block border-t cursor-pointer hover:bg-gray-200 border border-gray-300 rounded-bl-md" onClick={goToNextWeek}>
                    <ChevronDownIcon className="p-px h-7 w-7 text-gray-400"/>
                </li>
            </ul>
            <ul className="mr-2 flex flex-col justify-center items-center">
                <li className="block border-t cursor-pointer hover:bg-gray-200 border border-gray-300 rounded-tr-md" onClick={toggleCalendar}>
                    {calendarExtended && <MagnifyingGlassPlusIcon className="p-px h-7 w-7 text-gray-400"/>}
                    {!calendarExtended && <MagnifyingGlassMinusIcon className="p-px h-7 w-7 text-gray-400"/>}
                </li>
                <li className="block border-b cursor-pointer hover:bg-gray-200 border border-gray-300 rounded-br-md" onClick={goToTodayDate}>
                <HomeIcon className="p-px h-7 w-7 text-gray-400"/>
            </li>
        </ul>
</div>
    );
};

export default WeekRowDatePicker;