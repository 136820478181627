import React, {useMemo, Fragment} from 'react';
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/solid";
import {classNames, getAccountImageUrl} from "../../../utils/common";

const AccountPicker = ({label, account, setAccount, accounts, ...props}) => {
    const selectorAccountWidth = useMemo(() => {
        let maxWidth = 1;
        accounts.forEach(account => {
            let accountWidth = account.name.length * 8;
            if (accountWidth > maxWidth) {
                maxWidth = accountWidth;
            }
        });
        return maxWidth + 100 + 'px';
    }, [accounts]);

    return (
        <Listbox as="div" value={account || ''} onChange={setAccount} {...props}>
            {({ open }) => (
                <>
            <Listbox.Label className="ml-3 block text-sm font-medium text-gray-700"> {label}</Listbox.Label>
            <div className="mt-1 relative h-10">
                <Listbox.Button className="relative bg-white border border-gray-300 rounded-md shadow-sm h-10 pl-3 pr-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                               style={{minWidth: selectorAccountWidth}}>
                <span className="flex justify-between">
                    <span className="flex items-center block">
                        {account && <img src={getAccountImageUrl(account.logo)} alt="" className="flex-shrink-0 h-6 w-6 rounded-full"/>}
                        {account && <span className="ml-3 block whitespace-nowrap">{account.name}</span>}
                        {!account && <span className="ml-3 block whitespace-nowrap">No available account to transfer</span>}
                    </span>
                    <span className="ml-3 block flex items-center pointer-events-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </span>
                </span>
                </Listbox.Button>

                <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {accounts.map((accountOption) => (
                            <Listbox.Option
                                key={accountOption.id}
                                className={({ active }) =>
                                    classNames(active
                                            ? 'bg-indigo-600 text-white'
                                            : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9')
                                }
                                value={accountOption}
                            >
                                {({ selected, active }) => (
                                    <>
                                        <div className="flex items-center">
                                            <img src={getAccountImageUrl(accountOption.logo)} alt="" className="flex-shrink-0 h-6 w-6 rounded-full"/>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                {accountOption.name}
                                            </span>
                                        </div>
                                        {selected && <span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                        </span>}
                                    </>
                                )}
                            </Listbox.Option>)
                        )}
                    </Listbox.Options>
                </Transition>
            </div>
                </>)}
        </Listbox>
    );
};

export default AccountPicker;