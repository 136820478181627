import React, {Fragment} from 'react';
import {Listbox, Transition} from "@headlessui/react";
import {ChevronUpDownIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../../utils/common";

const AccountLogoPicker = ({logo, setLogo, logos}) => {
    return (
        <Listbox as="div" value={logo} onChange={setLogo} className="mt-5 mx-10">
            {({open}) => (
                <>
                    <Listbox.Label className="ml-3 block text-sm font-medium text-gray-700">Logo</Listbox.Label>
                    <div className="mt-1 relative h-10">
                        <Listbox.Button
                            className="relative bg-white border border-gray-300 rounded-md shadow-sm h-10 pl-3 pr-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm">
                                <span className="flex justify-between">
                                    <span className="flex items-center block">
                                        <div className="flex-shrink-0 h-8 w-8">
                                            <img className="h-8 w-8 rounded-full" src={`${process.env.PUBLIC_URL}/images/acc_logos/` + logo} alt=""/>
                                        </div>
                                    </span>
                                <span className="ml-3 block flex items-center pointer-events-none">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </span>
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {logos.map((logoOption) => (
                                    <Listbox.Option
                                        key={logoOption}
                                        className={({active}) =>
                                            classNames(active
                                                    ? 'bg-indigo-600 text-white'
                                                    : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9')
                                        }
                                        value={logoOption}
                                    >
                                        {({selected, active}) => (
                                            <>
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-8 w-8">
                                                        <img className="h-8 w-8 rounded-full" src={`${process.env.PUBLIC_URL}/images/acc_logos/` + logoOption} alt=""/>
                                                    </div>
                                                </div>
                                                {selected && <span
                                                    className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                                </span>}
                                            </>
                                        )}
                                    </Listbox.Option>)
                                )}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>)}
        </Listbox>
    );
};

export default AccountLogoPicker;