import {useMemo} from "react";
import {TransactionType} from "../model/TransactionType";


const useTrTypesSelector = (trType: TransactionType) => {
    const isCategoryType = useMemo(() => {
        return trType === TransactionType.Expense || trType === TransactionType.Income
    }, [trType]);
    const isExchangeType = useMemo(() => {
        return trType === TransactionType.Exchange
    }, [trType]);
    const isTransferOrExchangeType = useMemo(() => {
        return trType === TransactionType.Transfer || trType === TransactionType.Exchange
    }, [trType]);
    return {isCategoryType, isExchangeType, isTransferOrExchangeType};
}

export default useTrTypesSelector;