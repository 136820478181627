import React, {useMemo} from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/solid";
import {classNames, formPaginatingList} from "../../utils/common";

const PaginationBar = ({page, setPage, pageSize, setPageSize, itemsCount}) => {
    const paginatingSize = 1;
    const pageSizeOptions = [20, 50, 100, 200, 500];

    const pageCount = useMemo(() => Math.ceil(itemsCount / pageSize), [pageSize, itemsCount]);
    const pageList = useMemo(() => {
        const l = formPaginatingList(page, paginatingSize, pageCount);
        console.log(l);
        return l;
    }, [page, pageSize, pageCount]);

    const handleChangePageSize = (e) => {
        setPage(1);
        setPageSize(e.target.value);
    }

    const handleChangePage = (pageNumber) => {
        if (Number.isInteger(pageNumber) && pageNumber !== page && pageNumber > 0 && pageNumber <= pageCount) {
            setPage(pageNumber);
        }
    }

    const previousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const nextPage = () => {
        if (page < pageCount) {
            setPage(page + 1);
        }
    }

    return (
        // <div>
        <div className="flex items-center justify-between">
            <div className="flex-1 flex justify-between sm:hidden">
                <a onClick={previousPage}
                   className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                    Previous
                </a>
                <a onClick={nextPage}
                   className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                    Next
                </a>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        <span className="font-medium">{(page - 1) * pageSize + 1}</span>
                        {' '}
                        to
                        {' '}
                        <span className="font-medium">{page * pageSize < itemsCount ? page * pageSize : itemsCount}</span>
                        {' '}
                        of
                        {' '}
                        <span className="font-medium">{itemsCount}</span>
                    </p>
                </div>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <a onClick={previousPage}
                           className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100">
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                        </a>
                        {pageList.map((pageLink, index) =>
                            <a key={index}
                               onClick={() => handleChangePage(pageLink)}
                               aria-current="page"
                               className={classNames("relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer",
                                   page === pageLink
                                       ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                                       : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-100')}
                            >
                                {pageLink === 0 ? '...' : pageLink}
                            </a>)}
                        <a onClick={nextPage}
                           className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100">
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                        </a>
                    </nav>
                </div>
                <div>
                    <label>
                        <span className="text-sm text-gray-700 inline-block mr-3">
                            Page size:
                        </span>
                        <select value={pageSize}
                                onChange={handleChangePageSize}
                                className="rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100">
                            {pageSizeOptions.map(option =>
                                <option
                                    key={option}
                                    value={option}>
                                    {option}
                                </option>)}
                        </select>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default PaginationBar;