import React from 'react';
import TransactionTableItem from "./TransactionTableItem";
import {ICategoryTransactionResp} from "../../model/ITransaction";
import {TransactionType} from "../../model/TransactionType";
import {IFilterStats} from "../../model/IStats";

const TransactionReadOnlyTable: React.FunctionComponent<IFilterStats> = (result) => {
    return (
        <div id="transactions" className="flex flex-col">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="sticky top-16 bg-gray-50 z-30">
                <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Account
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Comment
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                    </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {result && result.transactions && result.transactions.map((transaction) =>
                    <TransactionTableItem
                        key={transaction.id}
                        transaction={transaction}
                        trType={TransactionType.Expense}
                        readOnly={true}/>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionReadOnlyTable;