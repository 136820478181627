import React from 'react';

const CategoryFilledCheckbox = ({selectFilled, setSelectFilled, ...props}) => {

    const handleSelectFilled = () => {
        setSelectFilled((selectFilled) => !selectFilled);
    };
    return (
        <div {...props}>
            <div className="flex h-6 items-center">
                <input
                    id="checkbox_all"
                    name="checkbox_all"
                    type="checkbox"
                    checked={selectFilled}
                    onChange={handleSelectFilled}
                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <div className="mx-2">Show filled with category</div>
            </div>
        </div>
    );
};

export default CategoryFilledCheckbox;