import React from 'react';

const CommentField = ({comment, setComment, withLabel = true, ...props}) => {
    return (
        // <div className="mt-5 mx-8">
        <div {...props}>
            {withLabel && <label htmlFor="comment" className="ml-3 block text-sm font-medium text-gray-700">Comment</label>}
            <div className="mt-1 relative rounded-md shadow-sm h-10">
                <input value={comment} onChange={(event) => setComment(event.target.value)} type="text" id="comment"
                       className="focus:ring-indigo-500 focus:border-indigo-500 block h-10 w-60 px-3 sm:text-sm border-gray-300 rounded-md"/>
            </div>
        </div>
    );
};

export default CommentField;