import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {getAuthToken} from "../store/reducers/AuthSlice";
import {ICategory, Tag} from "../model/ICategory";
import {TransactionType} from "../model/TransactionType";
import {ICurrency} from "../model/ICurrency";
import {Tags} from "./Tags";
import {IBulkUploadRow, IBulkUploadRowPatchRequest, IBulkUploadSession} from "../model/IBulkUpload";
import {getBaseUrl} from "./ApiUrls";
import {IBalance} from "../model/IAccount";
import {IFilterStats, IFilterStatsReq} from "../model/IStats";

export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    tagTypes: [Tags.AccountLogos, Tags.AccountsTotalBalance, Tags.BulkUploadSession, Tags.BulkUploadSessionRows],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl() + '/api/v1/',
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set('Authorization', token);
            }
            return headers;
        }
    }),
    endpoints: (build) => ({
        fetchAllCategories: build.query<ICategory[], TransactionType>({
            query: (trType) => ({
                url: 'categories/' + trType,
            }),
        }),
        fetchAllTags: build.query<Tag[], void>({
            query: () => ({
                url: 'categories/tag/',
            }),
        }),
        fetchAllCurrencies: build.query<ICurrency[], void>({
            query: () => ({
                url: 'currencies/currency/',
            }),
        }),
        fetchAllAccountLogos: build.query<string[], void>({
            query: () => ({
                url: 'accounts/logos/',
            }),
            providesTags: [Tags.AccountLogos],
        }),
        fetchAccountsTotalBalance: build.query<IBalance[], void>({
            query: () => ({
                url: 'accounts/total_balance/',
            }),
            providesTags: [Tags.AccountsTotalBalance],
        }),
        fetchAllBulkUploadSessions: build.query<IBulkUploadSession[], void>({
            query: () => ({
                url: 'bulk_upload/session/',
            }),
            providesTags: [Tags.BulkUploadSession],
        }),
        getBulkUploadSession: build.query<IBulkUploadSession, string>({
            query: (session_id) => ({
                url: 'bulk_upload/session/' + session_id + '/',
            }),
        }),
        // createBulkUploadSession: build.mutation<IBulkUploadSession, {account_id: number, file: ITransactionReq, comment: string}>({
        //     query: ({account_id, file, comment}) => {
        //         const session = new FormData();
        //         session.append("file", file);
        //         session.append("account", account_id.toString());
        //         session.append("comment", comment);
        //         return {
        //         url: '/',
        //         method: 'POST',
        //         body: session,
        //     }},
        //     invalidatesTags: [Tags.BulkUploadSession]
        // }),
        fetchAllBulkUploadRows: build.query<IBulkUploadRow[], string>({
            query: (session_id) => ({
                url: 'bulk_upload/rows/?session_id=' + session_id,
            }),
            providesTags: [Tags.BulkUploadSessionRows],
        }),
        patchBulkUploadRow: build.mutation<IBulkUploadRow, IBulkUploadRowPatchRequest>({
            query: ({id, ...body}) => ({
                url: 'bulk_upload/rows/' + id + '/',
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: [Tags.BulkUploadSessionRows],
        }),
        patchBulkUploadRows: build.mutation<IBulkUploadRow[], IBulkUploadRowPatchRequest[]>({
            query: (body) => ({
                url: 'bulk_upload/rows/',
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: [Tags.BulkUploadSessionRows],
        }),
        reconcileBulkUploadSession: build.mutation<void, string>({
            query: (id) => ({
                url: 'bulk_upload/session/' + id + '/reconcile/',
                method: 'POST',
            }),
            invalidatesTags: [Tags.BulkUploadSession, Tags.BulkUploadSessionRows],
        }),
        fetchStatsByFilter: build.query<IFilterStats, IFilterStatsReq>({
            query: (request) => ({
                url: 'transactions/stats/filter/',
                params: request,
            }),
        }),
    }),
});