import React, {useEffect, useState} from 'react';
import AccountField from "../ui/account/AccountField";
import CurrencyPicker from "../ui/account/CurrencyPicker";
import {categoryApi} from "../../api/CategoriesService";
import {accountApi} from "../../api/AccountsService";
import {IAccountReq} from "../../model/IAccount";
import AmountField from "../ui/transaction/AmountField";
import AccountLogoPicker from "../ui/account/AccountLogoPicker";

const NewAccountForm = () => {
    const {data: currencies} = categoryApi.useFetchAllCurrenciesQuery();
    const {data: accountLogos} = categoryApi.useFetchAllAccountLogosQuery();

    const [accountName, setAccountName] = useState('');
    const [accountLogo, setAccountLogo] = useState(null as any);
    const [currency, setCurrency] = useState(null as any);
    const [initialAmount, setInitialAmount] = useState(0.0);

    function clearForm() {
        setAccountName('');
        setAccountLogo(accountLogos ? accountLogos[0] : null);
        setCurrency(currencies ? currencies[0] : null);
        setInitialAmount(0);
    }

    const [addNewAccount, {isLoading}] = accountApi.useCreateAccountMutation();

    function composeNewAccount() : IAccountReq {
        return {
            name: accountName,
            currency: currency.id,
            logo: accountLogo,
            initial_balance: 0,
        }
    }

    async function handleAddNewAccount() {
        const result = await addNewAccount(composeNewAccount());
        if ('data' in result) {
            clearForm();
        } else {
            console.log(result.error);
        }
    }

    useEffect(() => {
        if (!currency && currencies) {
            setCurrency(currencies[0]);
        }
    }, [currencies]);

    useEffect(() => {
        if (!accountLogo && accountLogos) {
            setAccountLogo(accountLogos[0]);
        }
    }, [accountLogos]);

    return (
        <div id="account" className="mb-4 border-b border-gray-200">
            <div className="flex justify-center mx-6 px-1">
                {accountLogo && <AccountLogoPicker logo={accountLogo} setLogo={setAccountLogo} logos={accountLogos}/>}
                <AccountField label={'Account Name'} accountName={accountName} setAccountName={setAccountName}/>
                {currency && <CurrencyPicker label={'Currency'} currency={currency} setCurrency={setCurrency} currencies={currencies}/>}
                {currency && <AmountField label={'Initial Balance'} currency={currency} amount={initialAmount} setAmount={setInitialAmount}/>}
            </div>

            <div className="mx-10 my-4 flex items-center justify-center">
                <button className="mx-10 group relative flex justify-center py-2 px-6 border border-transparent
                                   text-sm font-medium rounded-md bg-gray-200 text-red-500 hover:text-red-800"
                        onClick={clearForm}>
                    Clear
                </button>
                <button className="mx-10 group relative flex justify-center py-2 px-6 border border-transparent
                                   text-sm font-medium rounded-md bg-green-100 text-green-800 hover:bg-green-200 focus:outline-none"
                        disabled={isLoading}
                        onClick={handleAddNewAccount}>
                    Add
                </button>
            </div>
        </div>
    );
};

export default NewAccountForm;