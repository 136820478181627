import React, {useEffect} from 'react';
import {useAppDispatch} from "../hooks/redux";
import {appSlice} from "../store/reducers/AppSlice";
import BulkUploadSessionsTable from "../components/bulk_upload/BulkUploadSessionsTable";

const BulkUploadPage = () => {
    const dispatch = useAppDispatch();
    const {setTitle} = appSlice.actions;

    useEffect(() => {
        dispatch(setTitle('Bulk Upload'));
    });

    return (
        <div className="mx-auto py-6 px-8">
            <BulkUploadSessionsTable/>
        </div>
    );
};

export default BulkUploadPage;