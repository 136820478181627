import React, {useEffect} from 'react';
import Navbar from "../../components/ui/Navbar";
import TitleBar from "../../components/ui/TitleBar";
import {Outlet, useNavigate} from "react-router-dom";
import {useAppSelector} from "../../hooks/redux";

const BaseLayout = () => {
    const title = useAppSelector(state => state.app.title);
    const user = useAppSelector(state => state.auth.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && window.location.pathname === '/') {
            navigate('/transactions');
        }
    }, []);

    return (
        <>
            <Navbar className="fixed top-0 w-full z-[100]"></Navbar>
            {title && <TitleBar title={title} className="mt-16"></TitleBar>}

            <main className="flex">
                <Outlet/>
            </main>
        </>
    );
};

export default BaseLayout;