import {useMemo} from "react";
import {IAccount} from "../model/IAccount";
import {createSelector} from "@reduxjs/toolkit";
import {UseQueryStateResult} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {accountApi} from "../api/AccountsService";

const useActiveAccountsSelector = () => {
    const selectActiveAccounts = useMemo(() => {
        const emptyArray: IAccount[] = []
        return createSelector(
            [(state: UseQueryStateResult<any, any>) => state.data],
            (data: IAccount[]) => data?.filter((account: IAccount) => account.is_active) ?? emptyArray
        )
    }, []);

    return accountApi.useFetchAllAccountsQuery(undefined, {
        selectFromResult: result => ({
            ...result,
            activeAccounts: selectActiveAccounts(result)
        })
    });
};

export default useActiveAccountsSelector;