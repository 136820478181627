import React from 'react';

const CheckboxWithLabel = ({label, checked, setChecked, ...props}) => {

    const handleChange = () => {
        setChecked((checked) => !checked);
    };
    return (
        <div {...props}>
            <div className="flex h-6 items-center">
                <input
                    id="checkbox_all"
                    name="checkbox_all"
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <div className="mx-2">{label}</div>
            </div>
        </div>
    );
};

export default CheckboxWithLabel;