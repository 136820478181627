import {eachDayOfInterval, startOfDay, add, sub, format, formatISO} from 'date-fns'

export function classNames(...classes) {
    return classes.join(' ');
}

export function getAccountImageUrl(logo) {
    return `${process.env.PUBLIC_URL}/images/acc_logos/` + logo;
}

export function trunc(date) {
    return formatISO(date, { representation: 'date' });
}

export function getFirstDayOfCurrentMonthDate() {
    const date = new Date();
    return trunc(new Date(date.getFullYear(), date.getMonth(), 1));
}

export function getFirstDayOfMonthDate(date_str) {
    const date = new Date(date_str)
    return trunc(new Date(date.getFullYear(), date.getMonth(), 1));
}

export function getLastDayOfMonthDate(date_str) {
    const date = new Date(date_str)
    return trunc(new Date(date.getFullYear(), date.getMonth() + 1, 0));
}

export function isTodayDate(date) {
    return trunc(new Date()) === trunc(date);
}

export function isDatesEqual(date1, date2) {
    return trunc(date1) === trunc(date2);
}

export function getPreviousWeekDate(date) {
    return sub(date, {weeks: 1});
}

export function getNextWeekDate(date) {
    return add(date, {weeks: 1});
}

export function formWeekDateList(date) {
    let interval;
    if (date.getDay() === 0) {
        interval = eachDayOfInterval({
            start: sub(date, {days: 6}),
            end: date
        });
    } else {
        interval = eachDayOfInterval({
            start: sub(date, {days: date.getDay() - 1}),
            end: add(date, {days: 7 - date.getDay()})
        });
    }
    return interval.map(date => {
        return {date: startOfDay(date), day: date.getDate(), weekDay: format(date, 'EEE') };
    });
}

export function formPaginatingList(page, size, pageCount) {
    let firstPageLink = page - size < 1 ? 1 : page - size;
    let lastPageLink = page + size > pageCount ? pageCount : page + size;
    if (firstPageLink !== 1 && lastPageLink !== pageCount) {
        if (firstPageLink === 2 && pageCount - lastPageLink === 1) {
            return [1, ...range(firstPageLink, lastPageLink), pageCount];
        } else if (firstPageLink === 2) {
            return [1, ...range(firstPageLink, lastPageLink), 0, pageCount];
        } else if (pageCount - lastPageLink === 1) {
            return [1, 0, ...range(firstPageLink, lastPageLink), pageCount];
        } else {
            return [1, 0, ...range(firstPageLink, lastPageLink), 0, pageCount];
        }
    } else if (firstPageLink !== 1) {
        if (firstPageLink === 2) {
            return [1, ...range(firstPageLink, lastPageLink)];
        } else {
            return [1, 0, ...range(firstPageLink, lastPageLink)];
        }
    } else if (lastPageLink !== pageCount) {
        if (pageCount - lastPageLink === 1) {
            return [...range(firstPageLink, lastPageLink), pageCount];
        } else {
            return [...range(firstPageLink, lastPageLink), 0, pageCount];
        }
    } else {
        return range(firstPageLink, lastPageLink);
    }
}

function range(start, end) {
    return Array(end - start + 1).fill().map((_, i) => start + i);
}
