import React from 'react';
import {IBulkUploadSession} from "../../model/IBulkUpload";
import {format} from "date-fns";
import {useNavigate} from "react-router-dom";

const BulkUploadSessionItem: React.FunctionComponent<IBulkUploadSession> = (session) => {
    const navigate = useNavigate();

    function handleRowClick() {
        console.log("click")
        navigate('/bulk_upload/sessions/' + session.id);
    }

    return (
        <tr onClick={handleRowClick}>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                { format(new Date(session.date), 'dd MMM yyyy HH:MM:SS') }
            </td>
            <td className="px-4 py-2 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                        <img className="h-10 w-10 rounded-full" src={`${process.env.PUBLIC_URL}/images/acc_logos/` + session.account_logo} alt=""/>
                    </div>
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            {session.account_name}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span className="px-2 mx-4 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                    { session.number_of_rows }
                </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <span className="px-2 mx-4 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    { session.processed_ratio } %
                </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                { session.comment }
            </td>
            {/*<td className="px-6 py-4 whitespace-nowrap">*/}
            {/*    <span className={classNames(*/}
            {/*        account.is_active ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800",*/}
            {/*        "px-2 inline-flex text-xs leading-5 font-semibold rounded-full")}*/}
            {/*    >*/}
            {/*        {account.is_active ? 'Active' : 'Disabled'}*/}
            {/*    </span>*/}
            {/*</td>*/}
            {/*<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">*/}
            {/*    <button className="cursor-pointer text-indigo-600 hover:text-indigo-900">Edit</button>*/}
            {/*</td>*/}
            {/*<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">*/}
            {/*    <button className="cursor-pointer text-red-600 hover:text-red-900"*/}
            {/*            disabled={isLoading}*/}
            {/*            onClick={handleSetAccountStatus}*/}
            {/*    >*/}
            {/*        {account.is_active ? 'Disable' : 'Enable'}*/}
            {/*    </button>*/}
            {/*</td>*/}
        </tr>
    );
};

export default BulkUploadSessionItem;