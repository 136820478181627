import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TransactionType} from "../../model/TransactionType";

interface AppState {
    title: string;
    trType: TransactionType;
}

const initialState: AppState = {
    title: '',
    trType: TransactionType.Expense,
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setTitle(state, action: PayloadAction<string>) {
            state.title = action.payload;
        },
        setTrType(state, action: PayloadAction<TransactionType>) {
            state.trType = action.payload;
        },
        clearAppData(state) {
            state = initialState;
        },
    }
})

export default appSlice.reducer;