import React, {useEffect} from 'react';
import {useAppDispatch} from "../../hooks/redux";
import {appSlice} from "../../store/reducers/AppSlice";
import {TransactionType} from "../../model/TransactionType";

const ExpenseTransactionsPage = () => {
    const dispatch = useAppDispatch();
    const {setTitle, setTrType} = appSlice.actions;

    useEffect(() => {
        dispatch(setTitle('Expense Transactions'));
        dispatch(setTrType(TransactionType.Expense));
    });

    return (
        <div>

        </div>
    );
};

export default ExpenseTransactionsPage;