import React from 'react';

const Loader = () => {
    return (
        <div className="absolute w-full h-full py-32">
            <div className="flex h-full items-center justify-center">
                <img src={`${process.env.PUBLIC_URL}/images/logo_centered.png`} className="w-10 h-10"
                     style={{animation: 'spin 1.5s linear infinite'}} alt=""/>
            </div>
        </div>
    );
};

export default Loader;