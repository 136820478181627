import React, {useEffect, useState} from 'react';
import {useAppDispatch} from "../hooks/redux";
import {appSlice} from "../store/reducers/AppSlice";
import SimpleDatePicker from "../components/ui/date_picker/SimpleDatePicker";
import * as common from "../utils/common";
import {categoryApi} from "../api/CategoriesService";
import {ICategory, Tag} from "../model/ICategory";
import {TransactionType} from "../model/TransactionType";
import CategoriesPicker from "../components/ui/transaction/CategoriesPicker";
import TagsPicker from "../components/ui/transaction/TagsPicker";
import StatsResult from "../components/stats/StatsResult";
import Loader from "../components/ui/Loader";
import CheckboxWithLabel from "../components/ui/CheckboxWithLabel";
import CommentField from "../components/ui/transaction/CommentField";
import TransactionReadOnlyTable from "../components/transactions/TransactionReadOnlyTable";

const StatsPage = () => {
    const dispatch = useAppDispatch();
    const {setTitle} = appSlice.actions;
    const [dateFrom, setDateFrom] = useState(common.getFirstDayOfCurrentMonthDate());
    const [dateTo, setDateTo] = useState(common.trunc(new Date()));

    const {data: allCategories} = categoryApi.useFetchAllCategoriesQuery(TransactionType.Expense);
    const {data: tagsList} = categoryApi.useFetchAllTagsQuery();
    const [categories, setCategories] = useState([] as ICategory[]);
    const [excludedCategories, setExcludedCategories] = useState([] as ICategory[]);
    const [comment, setComment] = useState('');
    const [tags, setTags] = useState([] as Tag[]);
    const [excludedTags, setExcludedTags] = useState([] as Tag[]);

    const [fetchTransactionsCheckbox, setFetchTransactionsCheckbox] = useState(false);
    const [evaluateFilter, {data: result, isFetching}] = categoryApi.useLazyFetchStatsByFilterQuery();

    useEffect(() => {
        dispatch(setTitle('Statistics'));
    });

    const handleEvaluateFilter = () => {
        evaluateFilter({
            date_from: dateFrom,
            date_to: dateTo,
            categories: categories.map(category => category.id),
            excluded_categories: excludedCategories.map(category => category.id),
            tags: tags.map(tag => tag.id),
            excluded_tags: excludedTags.map(tag => tag.id),
            fetch_transactions: fetchTransactionsCheckbox,
        });
    }

    const handleSetMonthRange = () => {
        setDateFrom(common.getFirstDayOfMonthDate(dateFrom));
        setDateTo(common.getLastDayOfMonthDate(dateFrom));
    }

    const handleClearFilters = () => {
        setCategories([]);
        setExcludedCategories([]);
        setTags([]);
        setExcludedTags([]);
    }

    const handleClearCategoriesFilters = () => {
        setCategories([]);
        setExcludedCategories([]);
    }

    return (
        <div className="mx-auto py-6 px-8">
            <div className="mb-4 pb-4 block border-b border-gray-300">
                <div className="flex flex-row">
                    <div className="block items-center">
                        <div className="flex flex-row items-center pl-5">
                            <p className="mx-10">Date From</p>
                            <SimpleDatePicker date={dateFrom} setDate={setDateFrom} className=""/>
                            <p className="mx-10">Date To</p>
                            <SimpleDatePicker date={dateTo} setDate={setDateTo} className=""/>
                        </div>
                        <div className="flex flex-row items-center">
                            {allCategories && <CategoriesPicker label="Categories" categories={categories} setCategories={setCategories} allCategories={allCategories} className="mt-5 mx-10"/>}
                            {allCategories && <CategoriesPicker label="Excluded Categories" categories={excludedCategories} setCategories={setExcludedCategories} allCategories={allCategories} className="mt-5 mx-10"/>}
                        </div>
                        <div className="flex flex-row items-center mb-4">
                            {tagsList && <TagsPicker label="Tags" tags={tags} setTags={setTags} tagsList={tagsList} className="mt-5 mx-5"/>}
                            {tagsList && <TagsPicker label="Excluded Tags" tags={excludedTags} setTags={setExcludedTags} tagsList={tagsList} className="mt-5 mx-5"/>}
                            <CommentField label="Comment" comment={comment} setComment={setComment} className="mt-5 mx-4"/>
                        </div>
                    </div>
                    <div className="block items-center">
                        <button className="mx-5 mt-5 group relative flex justify-center py-2 px-6 border border-transparent
                                               text-sm font-medium rounded-md bg-blue-100 text-green-800 hover:bg-blue-200 focus:outline-none"
                                onClick={handleSetMonthRange}>Set Month Range</button>
                        <button className="mx-5 mt-5 group relative flex justify-center py-2 px-6 border border-transparent
                                               text-sm font-medium rounded-md bg-blue-100 text-green-800 hover:bg-blue-200 focus:outline-none"
                                onClick={handleClearFilters}>Clear Filters</button>
                        <button className="mx-5 mt-5 group relative flex justify-center py-2 px-6 border border-transparent
                                               text-sm font-medium rounded-md bg-blue-100 text-green-800 hover:bg-blue-200 focus:outline-none"
                                onClick={handleClearCategoriesFilters}>Clear Categories Filters</button>
                    </div>
                </div>
                <div className="flex justify-center">
                    <CheckboxWithLabel label="Fetch transaction list" checked={fetchTransactionsCheckbox} setChecked={setFetchTransactionsCheckbox}
                                       className="mx-5 mt-5 flex items-center"/>

                    <button className="mx-5 mt-5 group relative flex justify-center py-2 px-6 border border-transparent
                                               text-sm font-medium rounded-md bg-green-100 text-green-800 hover:bg-green-200 focus:outline-none"
                            onClick={handleEvaluateFilter}>Evaluate</button>
                </div>
            </div>
            {result && !isFetching && <StatsResult {...result}/>}
            {isFetching && <div className="relative"><Loader/></div>}
            {result && result.transactions && result.transactions.length !== 0 && <TransactionReadOnlyTable {...result}/>}
        </div>
    );
};

export default StatsPage;